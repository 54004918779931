export const tr = {
    "socialMedia": {
        "facebook": "https://www.facebook.com/investturkey",
        "instagram": "https://www.instagram.com/investinturkiye_en",
        "linkedin": "https://www.linkedin.com/company/invest-in-turkey",
        "x": "https://twitter.com/InvestTurkey",
        "youtube": "https://www.youtube.com/c/InvestinT%C3%BCrkiye"
    },
    "heroSection": {
        "tagline": "DYY STRATEJİSİ",
        "heading": "Doğrudan Yabancı Yatırım Stratejisi",
        "description": "Bu stratejik yol haritası, Türkiye'nin ekonomik gelişim hedeflerine uygun yüksek kaliteli DYY yatırımlarını çekmeyi amaçlamaktadır."
    },
    "goals": [
        {
            "goalTag": "HEDEF 1",
            "goalHeading": "Türkiye'nin Küresel DYY Payını %1.5'e Çıkarma",
            "goalDescription": "Bu hedeflere ulaşmak için altı ana alanı kapsayan bir politika çerçevesi oluşturulmuştur. Bu politikaların uygulanması, DYY paydaşları ve ülkedeki kuruluşlarla işbirliği içinde geliştirilen yıllık eylem planları ile kolaylaştırılacaktır.",
            "goalNumber": "1.5",
            "mathSign":"%",
            "coverImage": {
                "src": "/images/fdi-strategy-cover_1.webp",
                "alt": "DYY Strateji Kapak",
                "sizes": "(max-width: 479px) 76vw, (max-width: 767px) 320px, (max-width: 991px) 400px, 480px",
                "srcset": "/images/fdi-strategy-cover_1-p-500.png 500w, /images/fdi-strategy-cover_1fdi-strategy-cover.webp 800w, /images/fdi-strategy-cover_1fdi-strategy-cover.webp 1080w, /images/fdi-strategy-cover_1.webp 1600w"
            }
        },
        {
            "goalTag": "HEDEF 2",
            "goalHeading": "Türkiye’nin Bölgesel DYY Girişlerindeki Payını %12'ye Çıkarma",
            "goalDescription": "Bu hedeflere ulaşmak için altı ana alanı kapsayan bir politika çerçevesi oluşturulmuştur. Bu politikaların uygulanması, DYY paydaşları ve ülkedeki kuruluşlarla işbirliği içinde geliştirilen yıllık eylem planları ile kolaylaştırılacaktır.",
            "goalNumber": "12",
            "mathSign":"%",
        },
        {
            "goalTag": "HEDEF 3",
            "goalHeading": "Her Kaliteli DYY Profili İçin 5 Yıllık Proje Hedefine Ulaşma",
            "goalNumber": "1,650",
            "fdiCards": [
                {
                    "iconSrc": "/images/ClimateFDI.svg",
                    "title": "İklim DYY",
                    "factNumber": "240",
                    "description": "DYY Projeleri",
                    "backHeading": "Küresel Değer Zincirleri (GDZ) Yeniden Şekillendiriliyor",
                    "backDescription": "Jeopolitik gerilimler, iklim değişikliği...",
                    "backImageSrc": "/images/GlobalFDI-Trend-01.webp"
                },
                {
                    "iconSrc": "/images/DigitalFDI.svg",
                    "title": "Dijital DYY",
                    "factNumber": "240",
                    "description": "DYY Projeleri",
                    "backHeading": "Küresel Değer Zincirleri (GDZ) Yeniden Şekillendiriliyor",
                    "backDescription": "Jeopolitik gerilimler, iklim değişikliği...",
                    "backImageSrc": "/images/GlobalFDI-Trend-01.webp"
                },
                {
                    "iconSrc": "/images/GVCRelatedFDI.svg",
                    "title": "Küresel Değer Zinciri İlişkili DYY",
                    "factNumber": "360",
                    "description": "DYY Projeleri",
                    "backHeading": "Küresel Değer Zincirleri (GDZ) Yeniden Şekillendiriliyor",
                    "backDescription": "Jeopolitik gerilimler, iklim değişikliği...",
                    "backImageSrc": "/images/GlobalFDI-Trend-01.webp"
                },
                {
                    "iconSrc": "/images/HighENDServiceFDI.svg",
                    "title": "Üst Düzey Hizmet DYY",
                    "factNumber": "270",
                    "description": "DYY Projeleri",
                    "backHeading": "Küresel Değer Zincirleri (GDZ) Yeniden Şekillendiriliyor",
                    "backDescription": "Jeopolitik gerilimler, iklim değişikliği...",
                    "backImageSrc": "/images/GlobalFDI-Trend-01.webp"
                },
                {
                    "iconSrc": "/images/HighQJobFDI.svg",
                    "title": "Yüksek Kaliteli İş Üreten DYY",
                    "factNumber": "360",
                    "description": "DYY Projeleri",
                    "backHeading": "Küresel Değer Zincirleri (GDZ) Yeniden Şekillendiriliyor",
                    "backDescription": "Jeopolitik gerilimler, iklim değişikliği...",
                    "backImageSrc": "/images/GlobalFDI-Trend-01.webp"
                },
                {
                    "iconSrc": "/images/Knowledge-IntensiveFDI.svg",
                    "title": "Bilgi Yoğun DYY",
                    "factNumber": "300",
                    "description": "DYY Projeleri",
                    "backHeading": "Küresel Değer Zincirleri (GDZ) Yeniden Şekillendiriliyor",
                    "backDescription": "Jeopolitik gerilimler, iklim değişikliği...",
                    "backImageSrc": "/images/GlobalFDI-Trend-01.webp"
                }
            ]
        }
    ],
    "policyAreasSection": {
        "tagline": "TEMEL İLKELER",
        "heading": "Politika Alanları",
        "cards": [
            {
                "id": "w-node-c915a300-f121-47df-acce-8975ad92eaee-ad92eaea",
                "href": "/action-plans#01",
                "title": "Yatırım İklimi ve Rekabetçilik"
            },
            {
                "id": "w-node-c915a300-f121-47df-acce-8975ad92eaef-ad92eaea",
                "href": "/action-plans#02",
                "title": "Yeşil Dönüşüm"
            },
            {
                "id": "w-node-c915a300-f121-47df-acce-8975ad92eaf3-ad92eaea",
                "href": "/action-plans#03",
                "title": "Dijital Dönüşüm"
            },
            {
                "id": "w-node-c915a300-f121-47df-acce-8975ad92eaf7-ad92eaea",
                "href": "/action-plans#04",
                "title": "Küresel Değer Zincirleri (GDZ)"
            },
            {
                "id": "w-node-c915a300-f121-47df-acce-8975ad92eafb-ad92eaea",
                "href": "/action-plans#05",
                "title": "Yetenek Havuzu"
            },
            {
                "id": "w-node-c915a300-f121-47df-acce-8975ad92eaff-ad92eaea",
                "href": "/action-plans#06",
                "title": "İletişim ve Tanıtım"
            }
        ]
    },
    "qualityFdiSlider": {
        "tagline": "FDI PROFILES",
        "heading": "Türkiye's Quality FDI Vision",
        "slides": [
            {
                "id": "slide1",
                "frontImage": "/images/GlobalFDI-Trend-05_1.webp",
                "frontAlt": "Climate FDI Image",
                "frontNumber": "01",
                "frontTitle": "Climate FDI",
                "backTitle": "Climate FDI",
                "backBoldText": "FDI that supports the green transformation plays a crucial role in managing risks related to climate change and in accelerating the transition of economies towards a more sustainable structure.",
                "backImage": "/images/GlobalFDI-Trend-05_1.webp",
                "backRegularText": "Investments that support Türkiye’s climate change adaptation and mitigation efforts are considered as ‘Quality FDI’ projects. In the last 10 years, Türkiye has attracted 121 FDI projects under this profile.",
                "items": [
                    "Investments in energy production from renewable energy sources, particularly solar, wind, geothermal, and green hydrogen",
                    "Investments in technologies and products used generating green energy (solar panels, wind turbines, and engines etc.)",
                    "Investments fostering the e-mobility ecosystem in the country, including electric/hydrogen vehicles and components, batteries, etc.",
                    "Investments supporting the circular economy",
                    "Service investments in environmental technologies",
                    "Investments that develop and produce technologies/products to increase energy efficiency"
                ]
            },
            {
                "id": "slide2",
                "frontImage": "/images/GlobalFDI-Trend-08_1.webp",
                "frontAlt": "Digital FDI Image",
                "frontNumber": "02",
                "frontTitle": "Digital FDI",
                "backTitle": "Digital FDI",
                "backBoldText": "Investments supporting digital transformation enhance countries’ competitiveness in various aspects. Particularly, these investments contribute to green transformation and create high quality jobs.",
                "backImage": "/images/GlobalFDI-Trend-08_1.webp",
                "backRegularText": "Investments that support the digital transformation of the Turkish economy are considered as ‘Quality FDI’ projects for Türkiye. In the last 10 years, Türkiye has attracted 214 FDI projects under this profile.",
                "items": [
                    "Investments in semiconductor, electronic components, and information communication technologies (ICT) sectors",
                    "Investments in digital service channels (e-commerce, hyperscalers, platform economies, and sharing economies etc.)",
                    "Investments in software and gaming sectors",
                    "Investments that enhance the country’s telecommunication infrastructure (broadband, wired and wireless connections, data centers, internet infrastructure etc.)",
                    "Investments supporting the digitalization of traditional sectors (3D printing, big data analytics, blockchain, cloud computing, cybersecurity, internet of things, virtual/augmented reality technologies, etc.)",
                    "Investments in Edtech, HealthTech, FinTech, smart home applications, and wearable technologies"
                ]
            },
            {
                "id": "slide3",
                "frontImage": "/images/GlobalFDI-Trend-01.webp",
                "frontAlt": "Global Value Chain FDI Image",
                "frontNumber": "03",
                "frontTitle": "Global Value Chain (GVC) Related FDI",
                "backTitle": "Global Value Chain (GVC) Related FDI",
                "backBoldText": "Industries deeply embedded in global value chains have greater prospects for generating high-quality employment, enhancing efficiency, and boosting exports. Additionally, such investments promote the dissemination of knowledge and technology transfer via strong linkages with domestic firms.",
                "backImage": "/images/GlobalFDI-Trend-01.webp",
                "backRegularText": "Investments in production, R&D, and logistics that enhance Türkiye’s integration into GVCs are considered as ‘Quality FDI’ projects. In the last 10 years, Türkiye has attracted 539 FDI projects under this profile.",
                "items": [
                    "Investments in production, R&D, design, and innovation centers in medium high-tech (e.g., automotive, machinery, and chemicals) and high-tech sectors (e.g., pharmaceuticals, aviation, and semiconductors).",
                    "Investments in infrastructure that enhance the country’s transportation, logistics, distribution, and storage capacities.",
                    "Logistics, distribution, and storage center investments regardless of its sector.",
                    "Investments of multinationals for procurement offices established in Türkiye."
                ]
            },
            {
                "id": "slide4",
                "frontImage": "/images/knowledge-intensive-fdi.avif",
                "frontAlt": "Knowledge Intensive FDI Image",
                "frontNumber": "04",
                "frontTitle": "Knowledge Intensive FDI",
                "backTitle": "Knowledge Intensive FDI",
                "backBoldText": "Investments in high-tech and knowledge-intensive sectors elevate the technological level of the country’s goods and services exports, create high-paid jobs, and nourish the start-up ecosystem.",
                "backImage": "/images/knowledge-intensive-fdi.avif",
                "backRegularText": "Knowledge-intensive investments that enhance the value-addition in the country are considered as ‘Quality FDI’ projects for Türkiye. In the last 10 years, Türkiye has attracted 357 FDI projects under this profile.",
                "items": [
                    "Manufacturing investments in high-tech sectors with significant potential for job creation in STEM fields (aviation, ICT, medical devices, pharmaceuticals, semiconductors, software, and information technology services, aerospace and defense, etc.).",
                    "R&D, design, and innovation center investments regardless of its sector.",
                    "Investments improving the country’s information communication infrastructure (beyond 5G technologies, internet, data centers, wireless communication infrastructures, etc.).",
                    "Investments in creative industries (architectural design, fashion design, filming industry, development of educational content and technologies, video games, etc.).",
                    "Investments increasing the use of high-quality service inputs in industry (3D printing, robotics, big data analytics, blockchain, cloud computing, cybersecurity, Internet of Things, virtual/augmented reality technologies, etc.)."
                ]
            },
            {
                "id": "slide5",
                "frontImage": "/images/0-new-centers2_1.avif",
                "frontAlt": "High-quality Job Generating FDI Image",
                "frontNumber": "05",
                "frontTitle": "High-quality Job Generating FDI",
                "backTitle": "High-quality Job Generating FDI",
                "backBoldText": "Drawing in high-value and knowledge-intensive investments can create high-quality jobs and significantly increase a country’s GDP per capital.",
                "backImage": "/images/0-new-centers2_1.avif",
                "backRegularText": "Investments that create quality and high-paid jobs are considered as ‘Quality FDI’ projects for Türkiye. In the last 10 years, Türkiye has attracted 507 FDI projects under this profile.",
                "items": [
                    "Investments for technical training and support centers, regional headquarters, shared services centers.",
                    "R&D, design, and innovation center investments regardless of its sector.",
                    "Production investments in high-tech sectors creating jobs in STEM fields.",
                    "Investments in creative industries (architectural design, fashion design, filming industry, development of educational content and technologies, video games, etc.).",
                    "Investments in the financial services, ICT, life sciences, and professional services sectors.",
                    "Investments increasing the use of high-quality service inputs in the industry (3D printing, robotics, big data analytics, blockchain, cloud computing, cybersecurity, Internet of Things, virtual/augmented reality technologies, etc.).",
                    "Investments in Edtech, HealthTech, FinTech, smart home applications, and wearable technologies."
                ]
            },
            {
                "id": "slide6",
                "frontImage": "/images/computer_1.avif",
                "frontAlt": "High-end Service FDI Image",
                "frontNumber": "06",
                "frontTitle": "High-end Service FDI",
                "backTitle": "High-end Service FDI",
                "backBoldText": "The weight of the services sector in global FDI is steadily increasing. Producing high value-added services is crucial for enhancing the generated value in the economy.",
                "backImage": "/images/computer_1.avif",
                "backRegularText": "Investments in the high-quality service sectors are considered as ‘Quality FDI’ projects for Türkiye. In the last 10 years, Türkiye has attracted 406 FDI projects under this profile.",
                "items": [
                    "R&D, design, and innovation center investments regardless of its sector.",
                    "Investments in high value-added business services sector (creative advertising, high quality consultancy, engineering services, technical training, legal, financial, and HR consultancy, etc.).",
                    "Investments increasing the use of high-quality service inputs in the industry (3D printing, robotics, big data analytics, blockchain, cloud computing, cybersecurity, Internet of Things, virtual/augmented reality technologies, etc.).",
                    "Investments in the banking, finance, and insurance sectors.",
                    "Investments in software and gaming sectors.",
                    "Regional headquarters.",
                    "Investments in maintenance & servicing and technical support centers.",
                    "Shared services centers."
                ]
            },
            {
                "id": "slide7",
                "frontImage": "/images/qualified_investment_1.avif",
                "frontAlt": "High-quality Financial FDI Image",
                "frontNumber": "07",
                "frontTitle": "High-quality Financial FDI",
                "backTitle": "High-quality Financial FDI",
                "backBoldText": "High-quality financial investments provide funds to innovative start-ups and enable them to grow and internationalize faster.",
                "backImage": "/images/qualified_investment_1.avif",
                "backRegularText": "Financial investments in sustainable and knowledge intensive areas are considered as ‘Quality FDI’ projects.",
                "items": [
                    "Fund investments strengthening Türkiye’s start-up ecosystem.",
                    "Fund investments supporting seed funding, crowdfunding ecosystems, and business angel networks in the country.",
                    "Sustainable fund investments made by venture capital funds, private equity funds, development finance institutions, family offices, sovereign wealth funds, pension funds, and asset management companies.",
                    "Investments supporting the participation finance ecosystem.",
                    "Impact investing."
                ]
            },
            {
                "id": "slide8",
                "frontImage": "/images/Local-Economic-Cultural-Development-1_1.avif",
                "frontAlt": "Regional Development Oriented FDI Image",
                "frontNumber": "08",
                "frontTitle": "Regional Development Oriented FDI",
                "backTitle": "Regional Development Oriented FDI",
                "backBoldText": "FDI makes significant contributions to increasing the competitiveness of the regions, strengthening the global integration of those regions and reducing the economic disparities within a country.",
                "backImage": "/images/Local-Economic-Cultural-Development-1_1.avif",
                "backRegularText": "Investments that activate the endogenous potential of regions within the country, provide local supply opportunities, create high employment, and thus reduce migration from the regions are considered as “Quality FDI” projects for Türkiye.",
                "items": [
                    "Investments made within the provinces at 3rd, 4th, 5th, and 6th level of development according to the Socio-Economic Development Indexation (SEDI) study;",
                    "Investments in industrial sectors with 50+ employment.",
                    "Investments in employment-intensive service sectors (call centers, logistics and distribution centers, etc.).",
                    "Investments in sustainable agriculture and livestock activities.",
                    "Investments to increase the tourism potential of the regions and develop the tourism infrastructure.",
                    "Investments in the above-mentioned seven quality FDI profiles."
                ]
            }
        ]
    },
    "qualityFdiSection": {
        "mockupImage": "/images/mockup_reduced_1.webp",
        "mockupAlt": "FDI Mockup Görseli",
        "pdfLink": "/documents/TR-FDI-Strategy-Report-Reduced.pdf",
        "pdfLabel": "PDF İndir",
        "tagline": "TEMEL İLKELER",
        "heading": "Türkiye'nin DYY Stratejisi",
        "features": [
            {
                "id": "1",
                "className": "content-features-column",
                "icon": "",
                "title": "Küresel Trendlerle Uyumlu",
                "description": "Küresel ekonomik dönüşüm ve Türkiye’nin ihtiyaçları dikkate alınarak DYY akışlarını etkileyebilecek trendler ayrıntılı bir şekilde analiz edilmiştir."
            },
            {
                "id": "w-node-aa06b421-a47c-8074-6cb5-f60b951b4972-951b493d",
                "className": "content-features-column",
                "icon": "",
                "title": "Sürdürülebilir Kalkınma Hedeflerine (SKH'lere) Yönelik",
                "description": "Türkiye’nin sürdürülebilir kalkınmasına katkı sağlayacak sekiz farklı türde kalite DYY profili hassas bir şekilde tanımlanmıştır."
            },
            {
                "id": "w-node-aa06b421-a47c-8074-6cb5-f60b951b494a-951b493d",
                "className": "content-feature-item",
                "icon": "",
                "title": "Veriye Dayalı Bir Yaklaşım",
                "description": "Her aşamada, birçok ulusal ve uluslararası veritabanına dayanan kapsamlı analizler yapılmıştır."
            },
            {
                "id": "w-node-aa06b421-a47c-8074-6cb5-f60b951b4969-951b493d",
                "className": "content-feature-item",
                "icon": "",
                "title": "Kaliteli DYY Çekimi İçin Ulusal Bir Yol Haritası",
                "description": "Kaliteli DYY projelerini çekmek için ulusal düzeyde tanımlanmış bir yol haritası oluşturulmuş, kurumsal çerçeve ve yönetim yapısı ayrıntılı olarak ele alınmıştır."
            },
            {
                "id": "w-node-aa06b421-a47c-8074-6cb5-f60b951b495f-951b493d",
                "className": "content-feature-item",
                "icon": "",
                "title": "Katılımcı Bir Süreç",
                "description": "Strateji, ilgili kamu kurumları, profesyonel iş örgütleri, mevcut uluslararası yatırımcılar, akademi ve uluslararası kuruluşlar dahil olmak üzere tüm önemli paydaşların katılımıyla tasarlanmıştır."
            }
        ]
    },
    "insightsSection": {
        "tagline": "DOĞRUDAN YATIRIM STRATEJİSİ",
        "heading": "Daha Fazla Bilgi Alın",
        "tabs": [
            {
                "id": "tab1",
                "tabId": "w-node-dbdb231f-3721-ba01-b35b-fe8525f08ce7-9d204e45",
                "icon": "",
                "title": "Temel Eğilimler & İçgörüler",
                "description": "Raporun Temel Eğilimlerine Genel Bakış",
                "cards": [
                    {
                        "id": "card1",
                        "tagline": "TEMEL EĞİLİMLER & İÇGÖRÜLER",
                        "heading": "Küresel ve Bölgesel Doğrudan Yatırım Akışları",
                        "description": "Küresel ve Bölgesel Doğrudan Yatırım Akışları hakkında daha fazla bilgi edinin.",
                        "link": "/insights#fdi-inflows",
                        "cardId": "w-node-_8de50c2f-4414-9316-7bae-0b3c7a75c0a4-9d204e45"
                    },
                    {
                        "id": "card2",
                        "tagline": "DOĞRUDAN YATIRIM STRATEJİSİ",
                        "heading": "CEEMENA Bölgesindeki Açıklanan Yatırım Projeleri",
                        "description": "Türkiye, gıda ve tarım ile sanayi sektörlerinde bölgenin en cazip yatırım destinasyonu haline gelmiştir.",
                        "link": "/insights#fdi-projects",
                        "cardId": "w-node-_3cb4207e-aef5-b5ab-6667-e33bc628ab5d-9d204e45"
                    },
                    {
                        "id": "card3",
                        "tagline": "DOĞRUDAN YATIRIM STRATEJİSİ",
                        "heading": "Küresel Doğrudan Yatırımda 8 Temel Eğilim",
                        "description": "Küresel doğrudan yatırım akışlarını şekillendiren temel eğilimler ve bölgesel ekonomiler üzerindeki etkileri.",
                        "link": "/insights#key-trends",
                        "cardId": "w-node-dd0b2880-ca5c-30d7-97f9-e9d606a5c1fb-9d204e45",
                        "carouselImages": [
                            {
                                "src": "/images/GlobalFDI-Trend-04.webp",
                                "alt": "Trend Görsel 1"
                            },
                            {
                                "src": "/images/GlobalFDI-Trend-01.webp",
                                "alt": "Trend Görsel 2"
                            },
                            {
                                "src": "/images/GlobalFDI-Trend-07_1.webp",
                                "alt": "Trend Görsel 3"
                            }
                        ]
                    },
                    {
                        "id": "card4",
                        "heading": "Temel Eğilimler ve İçgörüler Hakkında Daha Fazla Bilgi Edinin",
                        "description": "Temel eğilimler ve içgörüler hakkında daha fazla ayrıntı.",
                        "link": "/insights",
                        "cardId": "w-node-_8de50c2f-4414-9316-7bae-0b3c7a75c0b1-9d204e45"
                    }
                ]
            },
            {
                "id": "tab2",
                "tabId": "w-node-dbdb231f-3721-ba01-b35b-fe8525f08cf5-9d204e45",
                "icon": "",
                "title": "Türkiye'nin Doğrudan Yatırım Performansı",
                "description": "Türkiye'nin Doğrudan Yatırım Performansını Keşfedin",
                "cards": [
                    {
                        "id": "card1",
                        "tagline": "TÜRKİYE'NİN DOĞRUDAN YATIRIM PERFORMANSI",
                        "heading": "Yasal Düzenlemeler & Temel Faktörler",
                        "description": "Türkiye'nin olağanüstü doğrudan yatırım performansının arkasındaki sürekli reform süreci ve temel faktörleri keşfedin.",
                        "link": "/insights#fdi-performance",
                        "cardId": "w-node-_3b2fafe6-098a-7068-df95-95bf8d44ff90-9d204e45"
                    },
                    {
                        "id": "card2",
                        "tagline": "TÜRKİYE'NİN DOĞRUDAN YATIRIM PERFORMANSI",
                        "heading": "Türkiye'nin Girişimcilik Ekosistemi",
                        "description": "Türkiye'nin girişimcilik ekosistemindeki tüm kilit paydaşlar yerinde ve uluslararası yatırımcılara canlı bir ortam sunuyor.",
                        "link": "/insights#startup-ecosystem",
                        "cardId": "w-node-_3b2fafe6-098a-7068-df95-95bf8d44ff9d-9d204e45"
                    },
                    {
                        "id": "card3",
                        "tagline": "TÜRKİYE'NİN DOĞRUDAN YATIRIM PERFORMANSI",
                        "heading": "Doğrudan Yatırımın Türkiye Ekonomisine Katkısı",
                        "description": "Doğrudan yatırımın Türkiye ekonomisine olan önemli katkıları.",
                        "link": "/insights#contribution",
                        "cardId": "w-node-_3b2fafe6-098a-7068-df95-95bf8d44ffaa-9d204e45"
                    },
                    {
                        "id": "card4",
                        "heading": "Türkiye'nin Doğrudan Yatırım Performansı Hakkında Daha Fazla Bilgi Edinin",
                        "description": "Türkiye'nin doğrudan yatırım performansı ve başarı hikayeleri hakkında daha fazlasını keşfedin.",
                        "link": "/insights",
                        "cardId": "w-node-_3b2fafe6-098a-7068-df95-95bf8d44ffdb-9d204e45"
                    }
                ]
            },
            {
                "id": "tab3",
                "tabId": "w-node-dbdb231f-3721-ba01-b35b-fe8525f08d03-9d204e45",
                "icon": "",
                "title": "Temel Bulgular & Hedefler",
                "description": "Stratejiyi Yönlendiren 10 Temel Bulgu",
                "cards": [
                    {
                        "id": "card1",
                        "tagline": "TEMEL BULGULAR",
                        "heading": "Stratejiyi Yönlendiren 10 Temel Bulgu",
                        "description": "Doğrudan yatırım stratejisini ileriye taşıyan temel bulgular.",
                        "link": "/insights#key-findings",
                        "cardId": "w-node-_2bda0364-020c-dc46-9fb6-134667d5306a-9d204e45"
                    },
                    {
                        "id": "card2",
                        "heading": "Temel Bulgular Hakkında Daha Fazla Bilgi Edinin",
                        "description": "Doğrudan yatırım stratejisini şekillendiren temel bulgular hakkında daha fazla bilgi edinin.",
                        "link": "/insights",
                        "cardId": "w-node-_2bda0364-020c-dc46-9fb6-134667d5309b-9d204e45"
                    },
                    {
                        "id": "card3",
                        "tagline": "TEMEL BULGULAR",
                        "heading": "Stratejinin Ana Hedefi ve Politika Alanları",
                        "description": "Türkiye'nin doğrudan yatırım stratejisinin ana hedeflerini ve politika alanlarını keşfedin.",
                        "link": "/insights#goals",
                        "cardId": "w-node-_6ab104b9-9e8f-71a6-c9ef-3afbc8b2f88e-9d204e45"
                    }
                ]
            }
        ]
    },
    "insightsHero": {
        "tagline": "DYY STRATEJİSİ",
        "heading": "Görüşler ve Temel Bulgular",
        "paragraph": "Türkiye’nin DYY Stratejisini Şekillendiren Görüşler ve Temel Bulguların Kapsamlı Bir Özeti",
        "className": "cover-image insights",
        "id": "w-node-_315b066c-9375-5d43-64fe-99c0825fb648-bda32aac",
        "downloadButton": {
            "text": "PDF İndir",
            "link": "/documents/TR-FDI-Strategy-Report-Reduced.pdf"
        },
        "image": {
            "src": "/images/mockup_reduced_1.webp",
            "alt": "Görüşler Mockup Görseli",
            "srcset": "/images/mockup_reduced_1mockup_reduced.webp 500w, /images/mockup_reduced_1mockup_reduced.webp 800w, /images/mockup_reduced_1mockup_reduced.webp 1080w, /images/mockup_reduced_1.webp 1600w"
        }
    },
    "sectionIntro": {
        "icon": "",
        "tagline": "BULGULAR & ÖNGÖRÜLER",
        "heading1": "Küresel DYY Görünümü ve Temel Trendler"
    },
    "fdiInflows": {
        "tagline": "DYY STRATEJİSİ",
        "heading": "Küresel DYY Girişleri",
        "description": "Büyük çaplı birleşme ve devralmaların (M&A) neden olduğu zirveler hariç tutulduğunda, küresel DYY girişleri 2008 Küresel Finansal Krizi'nden bu yana duraklama eğiliminde. 2015 ve 2016'da bu tür zirveler yaşanmıştı.",
        "downloadLink": "/documents/TR-FDI-Strategy-Report-Reduced.pdf",
        "cards": [
            {
                "id": "w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f317-bda32aac",
                "title": "Küresel DYY'deki Değişen Dinamikler: Kriz Sonrası Durağanlık, Pandemi Etkisi ve Gelişmekte Olan Ülkelerin Yükselişi",
                "description": "2008 mali krizinden bu yana küresel DYY girişleri duraklama eğilimindedir. 2020'de COVID-19 nedeniyle önemli düşüşler yaşanmış, 2021'de birleşme ve devralma işlemleri ile yeniden artış görülmüştür. Son dönemde enflasyon ve jeopolitik gerilimler düşüşe neden olmuştur. Pandemi, tedarik zincirlerini yeniden şekillendirerek yeşil ve dijital sektörlerde yatırımları artırmıştır. 2018'den beri gelişmekte olan ülkeler, gelişmiş ülkeleri DYY girişlerinde sürekli olarak geride bırakmış ve bu eğilim 2020'den itibaren de devam etmiştir."
            },
            {
                "id": "w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f31e-bda32aac",
                "title": "Gelişmiş ve Gelişmekte Olan Ülkelerin Küresel DYY Girişlerindeki Payı (%)",
                "description": "Kullanıcı geri bildirimlerini analiz etmek ve değerli içgörüler elde etmek için Jambo'yu kullanın.",
                "imageUrl": "/images/FDI Strategy Report 2.png"
            },
            {
                "id": "w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f325-bda32aac",
                "bullets": [
                    {
                        "id": "w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f329-bda32aac",
                        "icon": "",
                        "className": "reddish",
                        "title": "2008 Sonrası Durağanlık",
                        "description": "2015-2016 zirveleri hariç DYY duraklaması"
                    },
                    {
                        "id": "w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f331-bda32aac",
                        "icon": "",
                        "className": "reddish",
                        "title": "Pandemi Düşüşü",
                        "description": "2020'de son 15 yılın en düşük DYY seviyeleri"
                    },
                    {
                        "id": "w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f339-bda32aac",
                        "icon": "",
                        "className": "reddish",
                        "title": "2021 Artışı",
                        "description": "Birleşme ve devralma işlemleri tarafından tetiklenen"
                    },
                    {
                        "id": "w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f341-bda32aac",
                        "icon": "",
                        "className": "reddish",
                        "title": "Aşağı Yönlü Eğilim",
                        "description": "Enflasyon ve jeopolitik gerilimler tarafından etkileniyor"
                    }
                ]
            },
            {
                "id": "w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f349-bda32aac",
                "bullets": [
                    {
                        "id": "w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f34d-bda32aac",
                        "icon": "",
                        "className": "green",
                        "title": "Tedarik Zincirinin Yeniden Şekillendirilmesi",
                        "description": "Pandemi sonrası yeni yatırımların artması"
                    },
                    {
                        "id": "w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f355-bda32aac",
                        "icon": "",
                        "className": "green",
                        "title": "Yeşil ve Dijital Dönüşüm",
                        "description": "Yükselen bir trend"
                    },
                    {
                        "id": "w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f35d-bda32aac",
                        "icon": "",
                        "className": "green",
                        "title": "Gelişmekte Olan Ülkeler Üstünlüğü",
                        "description": "2020'den beri gelişmiş ülkelerden daha fazla DYY girişleri"
                    },
                    {
                        "id": "w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f365-bda32aac",
                        "icon": "",
                        "className": "green",
                        "title": "Artan Pay",
                        "description": "2018'den beri gelişmekte olan ülkeler DYY'de lider"
                    }
                ]
            },
            {
                "id": "w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f31e-bda32aac",
                "title": "Küresel DYY Girişleri, Birleşme ve Devralma ve Yeni Yatırımlar*",
                "titleStrong": "(Trilyon USD)",
                "description": "Kullanıcı geri bildirimlerini analiz etmek ve değerli içgörüler elde etmek için Jambo'yu kullanın.",
                "imageUrl": "/images/FDI Strategy Report2.png"
            }
        ]
    },
    "infographicsSection": {
        "tagline": "YABANCI YATIRIM STRATEJİSİ",
        "heading": "Bölgede Yabancı Yatırım Girişleri",
        "description": "Orta ve Doğu Avrupa, Orta Doğu ve Kuzey Afrika'nın (CEEMENA) kesişme noktasında stratejik bir konumda bulunan Türkiye, önemli bir yabancı yatırım destinasyonu haline gelmiştir. Uluslararası yatırımcılar Türkiye'yi yeni yatırımlar için sık sık tercih etmektedir. Küresel Finansal Kriz sonrası düşüşün ardından CEEMENA'ya yapılan yabancı yatırım girişleri, 2015'te 72 milyar USD'den 2022'de 186 milyar USD'ye iki katından fazla arttı.",
        "bulletPoints": [
            {
                "icon": "M12 4.5C16.1423 4.5 19.5 7.85775 19.5 12C19.5 16.1423 16.1423 19.5 12 19.5C7.85775 19.5 4.5 16.1423 4.5 12C4.5 7.85775 7.85775 4.5 12 4.5ZM12 6C10.4087 6 8.88258 6.63214 7.75736 7.75736C6.63214 8.88258 6 10.4087 6 12C6 13.5913 6.63214 15.1174 7.75736 16.2426C8.88258 17.3679 10.4087 18 12 18C13.5913 18 15.1174 17.3679 16.2426 16.2426C17.3679 15.1174 18 13.5913 18 12C18 10.4087 17.3679 8.88258 16.2426 7.75736C15.1174 6.63214 13.5913 6 12 6ZM12 8.25C12.7894 8.2503 13.5586 8.49973 14.198 8.96275C14.8374 9.42578 15.3144 10.0788 15.561 10.8287C15.8075 11.5787 15.8112 12.3873 15.5713 13.1394C15.3314 13.8915 14.8603 14.5488 14.2251 15.0175C13.5898 15.4862 12.8229 15.7425 12.0335 15.7498C11.2441 15.7572 10.4725 15.5152 9.82864 15.0584C9.18479 14.6016 8.70153 13.9533 8.4477 13.2057C8.19388 12.4582 8.18243 11.6497 8.415 10.8953C8.59044 11.2873 8.89521 11.6072 9.27829 11.8014C9.66138 11.9956 10.0995 12.0523 10.5195 11.9621C10.9394 11.8719 11.3156 11.6402 11.5851 11.3057C11.8546 10.9713 12.0011 10.5545 12 10.125C12.0001 9.76385 11.8959 9.41036 11.6999 9.10701C11.5039 8.80366 11.2245 8.56336 10.8953 8.415C11.2448 8.30775 11.6153 8.25 12 8.25Z",
                "title": "Stratejik Konum",
                "description": "Türkiye'nin eşsiz pozisyonu"
            },
            {
                "icon": "M13.5 13.689V15.2565C12.8212 15.0165 12.0947 14.9429 11.3816 15.0418C10.6684 15.1408 9.9894 15.4094 9.40157 15.8252C8.81375 16.2409 8.33426 16.7916 8.00337 17.4311C7.67249 18.0705 7.49986 18.78 7.5 19.5L6 19.4993C5.99977 18.5834 6.20919 17.6797 6.61222 16.8573C7.01526 16.0349 7.6012 15.3157 8.32516 14.7548C9.04912 14.1939 9.89188 13.8061 10.7889 13.6212C11.6858 13.4364 12.6132 13.4593 13.5 13.6883V13.689ZM12 12.75C9.51375 12.75 7.5 10.7362 7.5 8.25C7.5 5.76375 9.51375 3.75 12 3.75C14.4862 3.75 16.5 5.76375 16.5 8.25C16.5 10.7362 14.4862 12.75 12 12.75ZM12 11.25C13.6575 11.25 15 9.9075 15 8.25C15 6.5925 13.6575 5.25 12 5.25C10.3425 5.25 9 6.5925 9 8.25C9 9.9075 10.3425 11.25 12 11.25ZM16.5 15.75V13.5H18V15.75H20.25V17.25H18V19.5H16.5V17.25H14.25V15.75H16.5Z",
                "title": "Yatırım Kısa Listesi",
                "description": "Yatırımcılar tarafından sık tercih edilen"
            },
            {
                "icon": "M11.25 4.53749V6.80249C8.706 7.16774 6.75 9.35624 6.75 12C6.75 14.8995 9.1005 17.25 12 17.25C13.179 17.25 14.268 16.8615 15.144 16.2045L16.7467 17.8072C15.4538 18.8647 13.8 19.5 12 19.5C7.85775 19.5 4.5 16.1422 4.5 12C4.5 8.11124 7.46025 4.91324 11.25 4.53749ZM19.4625 12.75C19.3125 14.2582 18.717 15.6352 17.8073 16.746L16.2045 15.144C16.7198 14.457 17.0693 13.6395 17.1968 12.75H19.4633H19.4625ZM12.7515 4.53749C16.2945 4.88924 19.1115 7.70699 19.464 11.25H17.1975C16.869 8.95124 15.0503 7.13249 12.7515 6.80324V4.53674V4.53749Z",
                "title": "Yatırım Düşüşü",
                "description": "2008 krizi sonrası düşüş"
            },
            {
                "icon": "M16.3807 14.7443L16.5128 14.877L16.6462 14.7443C16.8029 14.5876 16.989 14.4632 17.1937 14.3784C17.3985 14.2936 17.6179 14.25 17.8395 14.25C18.0611 14.25 18.2805 14.2936 18.4853 14.3784C18.69 14.4632 18.8761 14.5876 19.0328 14.7443C19.1895 14.901 19.3138 15.087 19.3986 15.2917C19.4834 15.4965 19.527 15.7159 19.527 15.9375C19.527 16.1591 19.4834 16.3785 19.3986 16.5833C19.3138 16.788 19.1895 16.974 19.0328 17.1307L16.5128 19.65L13.9943 17.1307C13.6778 16.8143 13.5 16.3851 13.5 15.9375C13.5 15.4899 13.6778 15.0607 13.9943 14.7443C14.3107 14.4278 14.7399 14.25 15.1875 14.25C15.6351 14.25 16.0643 14.4278 16.3807 14.7443ZM12 13.5V15C10.8065 15 9.66193 15.4741 8.81802 16.318C7.97411 17.1619 7.5 18.3065 7.5 19.5H6C6.00002 17.9412 6.6067 16.4436 7.6916 15.3242C8.7765 14.2049 10.2544 13.5517 11.8125 13.503L12 13.5ZM12 3.75C14.4862 3.75 16.5 5.76375 16.5 8.25C16.5004 9.4144 16.0493 10.5336 15.2416 11.3723C14.4338 12.2109 13.3323 12.7037 12.1688 12.747L12 12.75C9.51375 12.75 7.5 10.7362 7.5 8.25C7.49958 7.0856 7.95068 5.96641 8.75843 5.12774C9.56618 4.28908 10.6677 3.79629 11.8312 3.753L12 3.75ZM12 5.25C10.3425 5.25 9 6.5925 9 8.25C9 9.9075 10.3425 11.25 12 11.25C13.6575 11.25 15 9.9075 15 8.25C15 6.5925 13.6575 5.25 12 5.25Z",
                "title": "Yatırım Artışı",
                "description": "2015'ten 2022'ye ikiye katlanan girişler"
            }
        ],
        "downloadLink": "/documents/TR-FDI-Strategy-Report-Reduced.pdf",
        "downloadButton": "PDF İndir"
    },
    "fdiCardsSection": {
        "cards": [
            {
                "id": "w-node-a6ebe047-e62c-2f05-5b73-de940367235d-bda32aac",
                "title": "Bölgenin Küresel Yatırım Girişlerindeki Payı (%)",
                "description": "Uluslararası yatırımcılar, Türkiye ve CEEMENA ülkelerini yeni yatırımlar için sık sık değerlendiriyor. 40 ülkenin yer aldığı bölgesel bir analiz, Küresel Finansal Kriz'in ardından yatırım girişlerinin 2015 yılına kadar düştüğünü gösteriyor. O zamandan bu yana CEEMENA bölgesine yapılan yabancı yatırım girişleri 2015 yılında 72 milyar USD'den 2022'de 186 milyar USD'ye iki katından fazla arttı.",
                "imageUrl": "/images/The-Regions-Share-in-Global-FDI-Inflows.png",
                "imageAlt": "Bölgenin Küresel Yatırım Girişlerindeki Payı"
            },
            {
                "id": "w-node-a6ebe047-e62c-2f05-5b73-de9403672365-bda32aac",
                "className": "dark",
                "title": "Türkiye, CEEMENA bölgesindeki en önemli yatırım destinasyonlarından biri olarak öne çıkıyor.",
                "description": "Ülke bazında bir analiz yapıldığında, Türkiye son yirmi yılda (2003-2022) 252 milyar USD yatırım çekerek, CEEMENA bölgesinde %9.5'lik pazar payı ile üçüncü sırada yer alıyor. Ayrıca Türkiye, 2013-2022 döneminde pazar payını %10.4'e çıkararak, önceki on yıldaki (2003-2012) %9.2'lik oranını aşmıştır.",
                "iconUrl": "/images/Star-Graph.svg",
                "imageAlt": "Yıldız Grafiği"
            }
        ]
    },
    "fdiProjectsSection": {
        "tagline": "FDI STRATEJİSİ",
        "heading": "Türkiye: CEEMENA Bölgesinde Gıda, Tarım ve Endüstri Sektörlerinde Lider Yatırım Destinasyonu",
        "description": "Türkiye, gıda, tarım ve endüstri sektörlerinde bölgenin en cazip yatırım destinasyonu haline gelmiştir.",
        "downloadLink": "/documents/EN-FDI-Strategy-Report-Reduced.pdf",
        "downloadButton": "PDF İndir",
        "tabs": [
            {
                "id": "tab-1",
                "title": "Gıda ve Tarım",
                "description": "Türkiye, son 10 yılda gıda ve tarım sektöründeki payını üç kattan fazla artırmış olup, CEEMENA bölgesindeki her beş yatırım projesinden birine ev sahipliği yapmıştır.",
                "icon": "",
                "iconClass": "yellow",
                "imageUrl": "/images/FoodAgriculture-Infographics.png",
                "imageAlt": "Gıda ve Tarım Bilgi Grafiği"
            },
            {
                "id": "tab-2",
                "title": "Endüstri",
                "description": "Son 10 yılda Türkiye'nin endüstri sektöründeki payı neredeyse iki katına çıkmıştır. Otomotiv, makine ve kimya gibi küresel değer zincirlerine entegre sektörlerdeki üretim yatırımları bu artışta büyük rol oynamıştır.",
                "icon": "",
                "iconClass": "turqoise",
                "imageUrl": "/images/Industry-Infographics.png",
                "imageAlt": "Endüstri Bilgi Grafiği"
            },
            {
                "id": "tab-3",
                "title": "Hizmetler",
                "description": "Aynı zamanda Türkiye, hizmetler sektöründe bölgesel payını korumuş olup, son on yılda duyurulan yatırım projelerinin sayısında tutarlılık göstermiştir.",
                "icon": "",
                "iconClass": "brown",
                "imageUrl": "/images/Service-Infographics.png",
                "imageAlt": "Hizmetler Bilgi Grafiği"
            }
        ],
        "sourceInfo": "fDi Markets, 2023 & Yatırım Ofisi Analizleri"
    },
    "keyOutcomes": {
        "slides": [
            {
                "id": "slide-1",
                "keyInfoId": "w-node-_98fdce91-a221-1bc4-6d3d-37bb983c9b54-bda32aac",
                "contentWrapperId": "w-node-_98fdce91-a221-1bc4-6d3d-37bb983c9b63-bda32aac",
                "title": "INCREASE IN REGIONAL SHARE IN MANUFACTURING PROJECTS",
                "fact": "<span class='number-fact'>4.5</span> TIMES",
                "footer": "<strong>2003-2022:</strong><br>By the Number of Projects",
                "description": {
                    "heading": "FDI Projects Announced in the CEEMENA Region by Activity",
                    "content": "The distribution of announced FDI projects by activity type illustrates that manufacturing, research and development (R&D), and Shared Services Center (SSC) projects in the CEEMENA region have seen a substantial growth in the post-pandemic period. In particular, SSC projects have established a new record by surging approximately 2.5 times in the last 3 years.",
                    "highlight": "Türkiye is by far the leading investment destination in CEEMENA for manufacturing projects.",
                    "details": "Between 2013 and 2022, compared to the previous decade, Türkiye has increased its share in manufacturing projects by nearly 4.5 times, becoming by far the leading country in CEEMENA with 21.7% share. On the other hand, it has maintained its regional share in R&D and SSC projects."
                }
            },
            {
                "id": "slide-2",
                "keyInfoId": "w-node-_98fdce91-a221-1bc4-6d3d-37bb983c9b72-bda32aac",
                "contentWrapperId": "w-node-_98fdce91-a221-1bc4-6d3d-37bb983c9b87-bda32aac",
                "title": "IN M&A INVESTMENTS",
                "fact": "<span class='number-fact'>2</span>ND MOST<br>ATTRACTIVE<br>COUNTRY<br>IN THE REGION",
                "footer": "<strong>2003-2022:</strong><br>By the Number of Projects",
                "description": {
                    "heading": "M&A Investments in CEEMENA",
                    "content": "The region, which covers Central and Eastern Europe, the Middle East, and North Africa, is among the world’s leading destinations not only for greenfield investments but also for M&A projects. After the pandemic, approximately 10% of global M&A transactions (based on both the number of projects and the investment amount) have been announced in this region.",
                    "highlight": "Türkiye is the second most attractive destination for M&A investments in CEEMENA.",
                    "details": "Based on the number of projects, Türkiye has attracted 8.3% of M&A projects in CEEMENA over the past 20 years."
                }
            },
            {
                "id": "slide-3",
                "keyInfoId": "w-node-_330a69f9-c3c3-7b8d-a818-b9162e580f7d-bda32aac",
                "contentWrapperId": "w-node-_330a69f9-c3c3-7b8d-a818-b9162e580f8c-bda32aac",
                "title": "IN GREENFIELD INVESTMENTS",
                "fact": "<span class='number-fact'>3</span>RD MOST<br>ATTRACTIVE<br>COUNTRY<br>IN THE REGION",
                "footer": "<strong>2013-2022:</strong><br>By the Number of Projects",
                "description": {
                    "heading": "FDI Projects Announced in the CEEMENA Region by Activity",
                    "content": "The number of announced greenfield projects in CEEMENA experienced a significant downturn in 2009 due to the Global Financial Crisis and demonstrated a stable trend in the following years until the outbreak of the pandemic. However, it recovered rapidly after the pandemic and reached its highest level of the last two decades in 2022. The number of greenfield projects announced in 2022 increased by 83% compared to 2020.",
                    "highlight": "Türkiye is among the top countries in CEEMENA in terms of attracting greenfield FDI.",
                    "details": "In the 2013-2022 period, compared to the previous decade, Türkiye registered a notable increase in its share of greenfield investments and became among the top three countries in CEEMENA."
                }
            },
            {
                "id": "slide-4",
                "keyInfoId": "w-node-a1c8d248-9cd8-03a7-57ee-8971955b7daa-bda32aac",
                "contentWrapperId": "w-node-a1c8d248-9cd8-03a7-57ee-8971955b7dc1-bda32aac",
                "title": "INCREASE IN REGIONAL SHARE IN EXPANSION PROJECTS",
                "fact": "<span class='number-fact'>5</span> TIMES",
                "footer": "<strong>2013-2022:</strong><br>Compared to Previous Decade",
                "description": {
                    "heading": "EXPANSION-TYPE FDI PROJECTS ANNOUNCED IN CEEMENA",
                    "content": "The number of expansion investments announced in CEEMENA has generally been on an upward trend in the last decade. The number of expansion investments announced in 2022 increased by 61% compared to 2020.",
                    "highlight": "Türkiye is by far the leading country in CEEMENA in expansion investments.",
                    "details": "In the 2013-2022 period, compared to the previous decade, Türkiye increased its share in expansion type investments approximately 5 times (from 3.9% to 19.1%), becoming by far the leading country in CEEMENA. This indicates that international companies which have investments in the country, opt for Türkiye again to expand their operations in CEEMENA and to strengthen their regional value chains."
                }
            }
        ]
    },
    "fdiTrends": {
        "tagline": "FDI STRATEGY",
        "heading": "Global FDI Outlook and Key Trends",
        "description": "To realize these goals, a policy framework encompassing six major areas has been established. The implementation of these policies will be facilitated through annual action plans developed in collaboration with FDI stakeholders and organizations in the country.",
        "downloadText": "Download PDF",
        "pdfLink": "/documents/EN-FDI-Strategy-Report-Reduced.pdf",
        "flipCards": [
            {
                "id": "trend-01",
                "frontImage": "/images/GlobalFDI-Trend-01.webp",
                "frontAlt": "Global Value Chains (GVCs) Are Being Reshaped",
                "frontNumber": "01",
                "frontTitle": "Global Value Chains (GVCs) Are Being Reshaped",
                "frontDescription": "Geopolitical and environmental factors are reconfiguring global value chains.",
                "backTitle": "Global Value Chains (GVCs) Are Being Reshaped",
                "backBoldText": "Geopolitical tensions, climate change and natural disasters, pandemics, and the increasing importance of sustainability and digitalization concepts are leading to a reconfiguration of global value chains.",
                "backImage": "/images/GlobalFDI-Trend-01.webp",
                "backRegularText": "",
                "frontTagline": "FDI TRENDS"
            },
            {
                "id": "trend-02",
                "frontImage": "/images/GlobalFDI-Trend-02_1.webp",
                "frontAlt": "Deglobalization and Protectionism Trends Are Strengthening",
                "frontNumber": "02",
                "frontTitle": "Deglobalization and Protectionism Trends Are Strengthening",
                "frontDescription": "Recent economic tensions have prompted countries to adopt more protectionist trade and investment policies.",
                "backTitle": "Deglobalization and Protectionism Trends Are Strengthening",
                "backBoldText": "Recent tensions among the economic blocs have led countries to adopt more protectionist and inward-looking policies in investment and trade, driven by national security concerns.",
                "backImage": "/images/GlobalFDI-Trend-02_1.webp",
                "backRegularText": "",
                "frontTagline": "FDI TRENDS"
            },
            {
                "id": "trend-03",
                "frontImage": "/images/GlobalFDI-Trend-03_1.webp",
                "frontAlt": "Global Economic Uncertainties Are Surging",
                "frontNumber": "03",
                "frontTitle": "Global Economic Uncertainties Are Surging",
                "frontDescription": "Post-crisis inefficiencies and pandemic measures led to widespread adoption of expansionary fiscal and monetary policies.",
                "backTitle": "Global Economic Uncertainties Are Surging",
                "backBoldText": "Geopolitical tensions, climate change and natural disasters, pandemics, and the increasing importance of sustainability and digitalization concepts are leading to a reconfiguration of global value chains.",
                "backImage": "/images/GlobalFDI-Trend-03_1.webp",
                "backRegularText": "",
                "frontTagline": "FDI TRENDS"
            },
            {
                "id": "trend-04",
                "frontImage": "/images/GlobalFDI-Trend-04.webp",
                "frontAlt": "Economic and Political Fragmentation on a Global Scale Is Deepening",
                "frontNumber": "04",
                "frontTitle": "Economic and Political Fragmentation on a Global Scale Is Deepening",
                "frontDescription": "Global fragmentations are driving protectionist measures, significantly impacting FDI and financial investment flows.",
                "backTitle": "Economic and Political Fragmentation on a Global Scale Is Deepening",
                "backBoldText": "Recent global economic and political fragmentations have led to protectionist measures, significantly impacting the global economy.",
                "backImage": "/images/GlobalFDI-Trend-04.webp",
                "backRegularText": "",
                "frontTagline": "FDI TRENDS"
            },
            {
                "id": "trend-05",
                "frontImage": "/images/GlobalFDI-Trend-05_1.webp",
                "frontAlt": "Climate Change and Environmental Sustainability Play a Transformative Role in FDI",
                "frontNumber": "05",
                "frontTitle": "Climate Change and Environmental Sustainability Play a Transformative Role in FDI",
                "frontDescription": "Geopolitical and environmental factors are reconfiguring global value chains.",
                "backTitle": "Climate Change and Environmental Sustainability Play a Transformative Role in FDI",
                "backBoldText": "In recent years, climate change mitigation and adaptation strategies have become one of the most critical agenda items for the private sector and policy makers.",
                "backImage": "/images/GlobalFDI-Trend-05_1.webp",
                "backRegularText": "",
                "frontTagline": "FDI TRENDS"
            },
            {
                "id": "trend-06",
                "frontImage": "/images/GlobalFDI-Trend-06_1.webp",
                "frontAlt": "Digital Transformation Is Accelerating",
                "frontNumber": "06",
                "frontTitle": "Digital Transformation Is Accelerating",
                "frontDescription": "The acceleration of the digitalization process on a global level is profoundly transforming traditional sectors.",
                "backTitle": "Digital Transformation Is Accelerating",
                "backBoldText": "The acceleration of the digitalization process on a global level is transforming traditional sectors and facilitating the growth of new ones.",
                "backImage": "/images/GlobalFDI-Trend-06_1.webp",
                "backRegularText": "",
                "frontTagline": "FDI TRENDS"
            },
            {
                "id": "trend-07",
                "frontImage": "/images/GlobalFDI-Trend-07_1.webp",
                "frontAlt": "Regional and National Industrial Policies Are Gaining Greater Importance",
                "frontNumber": "07",
                "frontTitle": "Regional and National Industrial Policies Are Gaining Greater Importance",
                "frontDescription": "Industrial policies are gaining momentum with major economies.",
                "backTitle": "Regional and National Industrial Policies Are Gaining Greater Importance",
                "backBoldText": "Major economies are designing comprehensive incentive packages for strategic sectors under these new industrial policies.",
                "backImage": "/images/GlobalFDI-Trend-07_1.webp",
                "backRegularText": "",
                "frontTagline": "FDI TRENDS"
            },
            {
                "id": "trend-08",
                "frontImage": "/images/GlobalFDI-Trend-08_1.webp",
                "frontAlt": "New Technologies and High-Quality Service Inputs Are Transforming the Manufacturing Industry",
                "frontNumber": "08",
                "frontTitle": "New Technologies and High-Quality Service Inputs Are Transforming the Manufacturing Industry",
                "frontDescription": "AI, robotics, and 3D printing are revolutionizing manufacturing, reducing the importance of labor costs in attracting investments.",
                "backTitle": "New Technologies and High-Quality Service Inputs Are Transforming the Manufacturing Industry",
                "backBoldText": "The widespread adoption of AI-enhanced automation systems, robotics, and 3D printing is rapidly revolutionizing the manufacturing industry.",
                "backImage": "/images/GlobalFDI-Trend-08_1.webp",
                "backRegularText": "",
                "frontTagline": "FDI TRENDS"
            }
        ]
    },
    "fdiPerformance": {
        "id": "fdi-performance",
        "header": {
            "icon": "<span class='fontawesome'></span>",
            "tagline": "INSIGHTS & FINDINGS",
            "heading": "Türkiye's FDI Performance",
             "description":"Over the past 20 years, Türkiye has emerged as an attractive FDI destination thanks to various factors, including continuous and strong reform agenda, economic and political stability, a young and dynamic population, and a strategic location it offers."
        },
        "cards": [
            {
                "id": "w-node-a3d0e202-8c68-27c1-4f72-91fb61149c02-bda32aac",
                "title": "New Legal Regulations and Continuous Reform Process",
                "content": "<blockquote>Foreign Direct Investment (FDI) Law</blockquote><blockquote>Comprehensive Incentive Schemes</blockquote><blockquote>Strong Agenda for Sustainability and Green Transformation</blockquote><blockquote>Establishment of the Coordination Council for the Improvement of the Investment Environment (YOIKK)</blockquote><blockquote>Establishment of the Investment Office of Türkiye Reforms in Fundamental Laws</blockquote><blockquote>Judicial Reforms to Improve the Investment Environment</blockquote><blockquote>Strong Engagement with Ease of Doing Business / Business Ready (B-READY) Indices</blockquote>"
            },
            {
                "id": "w-node-_9597edd6-e4a9-c1c1-94ed-00f8328a69e7-bda32aac",
                "title": "Key Factors Lying Behind Türkiye’s Outstanding FDI Performance",
                "content": "<ul><li>Continuous Economic and Political Stability</li><li>Resilience Against Global Shocks</li><li>Young and Dynamic Population</li><li>Highly Skilled Talent Pool</li><li>Robust Infrastructure</li><li>Digital Transformation</li><li>Vibrant R&D Ecosystem</li><li>Geostrategic Location</li><li>Customs Union with European Union (EU)</li><li>A Wide Range of Free Trade Agreements</li><li>Strong Integration into Global Value Chains (GVCs)</li><li>Robust Economic Growth</li><li>Production and Export Hub of the Region</li></ul>"
            }
        ],
        "statisticsCard": {
            "id": "w-node-_9909d3a2-4e8e-300a-e2aa-d73a9ab7a136-bda32aac",
            "title": "Türkiye's FDI Performance Statistics",
            "statistics": "<div class='stats-grid'><div class='stat-item'><div class='h6-heading yellow'>Total FDI Inflows</div><div class='counter-wrap grayscale'><div class='stat-heading'>15 Billion</div><div class='counter-text'>1973-2002</div></div><div class='counter-wrap'><div class='stat-heading'>262 Billion</div><div class='counter-text'>2003-2023</div></div></div><div class='stat-item'><div class='h6-heading yellow'>Türkiye's Share in the Global FDI</div><div class='counter-wrap grayscale'><div class='stat-heading'>0.2%</div><div class='counter-text'>1990-2002</div></div><div class='counter-wrap'><div class='stat-heading'>0.9%</div><div class='counter-text'>2003-2023</div></div></div><div class='stat-item'><div class='h6-heading yellow'>Number of International Companies Operating in Türkiye</div><div class='counter-wrap grayscale'><div class='stat-heading'>5,600</div><div class='counter-text'>2003</div></div><div class='counter-wrap'><div class='stat-heading'>82,700</div><div class='counter-text'>2023</div></div></div><div class='stat-item'><div class='h6-heading yellow'>FDI Contribution to Current Account Balance</div><div class='counter-wrap'><div class='stat-heading'>40%</div><div class='counter-text'>2003-2023</div></div></div></div>"
        }
    },
    "startupEcosystem": {
        "tagline": "FDI STRATEGY",
        "heading": "Türkiye's Startup Ecosystem",
        "description": "Turkish startup ecosystem has all key stakeholders in place and offers a vivid environment to international investors.",
        "pdfLink": "/documents/EN-FDI-Strategy-Report-Reduced.pdf",
        "buttonText": "Download PDF",
        "cards": [
            {
                "id": "w-node-b7006ced-f850-0673-fd0f-76ed306bf935-bda32aac",
                "heading": "Türkiye’s Startup Ecosystem Reaches New Heights in Early-Stage Investments",
                "description": "Between 2010 and 2020, Türkiye’s startup ecosystem attracted $74 million annually through 104 transactions on average. From 2021 to 2023, investments soared to $1.33 billion annually, with an average of 338 transactions.",
                "image": "/images/EN-FDI-Strategy-Report_2.png",
                "alt": "Startup Ecosystem Image"
            },
            {
                "id": "w-node-b7006ced-f850-0673-fd0f-76ed306bf93c-bda32aac",
                "heading": "The Number of Unicorns",
                "description": "2020-2023",
                "number": "5"
            },
            {
                "id": "w-node-c96aa4eb-355c-cee6-1fb6-1d84c63953dc-bda32aac",
                "heading": "The Number of Decacorns",
                "description": "2020-2023",
                "number": "2"
            }
        ]
    },
    "contributionSection": {
        "tagline": "FDI STRATEGY",
        "heading": "The Contribution of FDI to the Turkish Economy",
        "description": "FDI in Türkiye has helped finance the current account deficit and significantly contributed to the real economy through the activities of FDI companies.",
        "source": "<strong>Source:</strong> fDi Markets, 2023 & Investment Office’s Analyses",
        "tabs": [
            {
                "id": "employment",
                "iconClass": "yellow",
                "icon": "",
                "heading": "Employment",
                "content": "<div id=\"w-node-_02c9d632-bf27-911c-c94e-583f1e56e75e-bda32aac\" class=\"tab-bento-card\"><div class=\"bento-card-header gap20\"><div id=\"w-node-_78da1cfc-2e04-5e0f-4e01-b04d2a9e0574-bda32aac\" class=\"stats-grid\"><div class=\"h6-heading yellow\">Direct Employment</div><div class=\"counter-wrap grayscale\"><div fb-count-delay=\"800\" fb-count=\"true\" fb-count-target=\"250\" fb-count-time=\"3000\" class=\"stat-heading\">500,600 People</div><div class=\"counter-text\">2006</div></div><div class=\"counter-wrap\"><div fb-count-delay=\"800\" fb-count=\"true\" fb-count-target=\"250\" fb-count-time=\"3000\" class=\"stat-heading\">1,320,000 People</div><div class=\"counter-text\">2021</div></div></div><div id=\"w-node-_1eed080e-8ab4-37d1-ed55-d6ca9bf9dce7-bda32aac\" class=\"stats-grid gap20\"><div id=\"w-node-_33ca0750-6a3e-5dca-d9d3-4217311f3170-bda32aac\" class=\"h6-heading yellow\"><strong>FDI’s share within the total private sector employment</strong></div><img sizes=\"218.515625px\" srcset=\"https://cdn.prod.website-files.com/666f68e852029a839d204e3c/66b76e2728b4fd5accfce313_Employment%20Rate-p-500.png 500w, https://cdn.prod.website-files.com/666f68e852029a839d204e3c/66b76e2728b4fd5accfce313_Employment%20Rate.png 874w\" alt=\"\" src=\"https://cdn.prod.website-files.com/666f68e852029a839d204e3c/66b76e2728b4fd5accfce313_Employment%20Rate.png\" loading=\"lazy\" class=\"stat-graph _400px\"><p class=\"description\">While the registered employment of FDI companies was around 500,000 people in 2006, it reached 1,320,000 in 2021. <br>The share of these companies in total registered employment in Türkiye increased from 7.6% in 2006 to 8.8% in 2021.</p></div></div></div>"
            },
            {
                "id": "exports",
                "iconClass": "turqoise",
                "icon": "",
                "heading": "Exports",
                "content": "<div id=\"w-node-cc6eb5a8-0dfa-319c-c4b5-c78e87172e80-bda32aac\" class=\"tab-bento-card\"><div class=\"bento-card-header gap0\"><div class=\"stats-grid grid no-border\"><div id=\"w-node-e420dc75-08be-1f26-11e6-acdb03959688-bda32aac\" class=\"h6-heading yellow\"><strong>The Share of FDI Companies In Türkiye's Total Export</strong></div><img loading=\"lazy\" src=\"https://cdn.prod.website-files.com/666f68e852029a839d204e3c/66b772d47f26e84226d2a05a_%5BEN%5D%20FDI%20Strategy%20Report.png\" alt=\"\" class=\"stat-graph\"><p class=\"description\">In the 2006-2021 period, approximately 31% of Türkiye’s total exports were made by FDI companies.</p></div><div class=\"stats-grid grid\"><div><h5 class=\"h6-heading turqoise right-aligned\"><span class=\"stat-heading\">2.5</span> TIMES<br>INCREASE</h5></div><p class=\"description\">While the exports of FDI companies were around 25 billion dollars in 2006, they increased to over 63 billion dollars in 2021.</p></div><div class=\"stats-grid grid\"><div><h5 class=\"h6-heading turqoise right-aligned\"><span class=\"stat-heading\">61%</span> MEDIUM&nbsp;HIGH-TECH &amp;&nbsp;HIGH-TECH&nbsp;EXPORTS</h5></div><p class=\"description\">In the 2006-2021 period, 61% of exports made by FDI companies consist of medium-high and high technology products. This rate is above the Turkish average.</p></div></div></div>"
            },
            {
                "id": "rnd",
                "iconClass": "brown",
                "icon": "",
                "heading": "Research & Development",
                "content": "<div id=\"w-node-_9d167489-4fae-9d43-6d1a-8502651eee1c-bda32aac\" class=\"tab-bento-card\"><div class=\"bento-card-header gap0\"><div class=\"stats-grid grid no-border\"><div id=\"w-node-_9d167489-4fae-9d43-6d1a-8502651eee1f-bda32aac\" class=\"h6-heading yellow\"><strong>The Share of FDI Companies In Private Sectors Total R&D Expenditures</strong></div><img sizes=\"100vw\" srcset=\"https://cdn.prod.website-files.com/666f68e852029a839d204e3c/66b782e636af13b973a460b4_R%26D%20Stats-p-500.png 500w, https://cdn.prod.website-files.com/666f68e852029a839d204e3c/66b782e636af13b973a460b4_R%26D%20Stats.png 559w\" alt=\"\" src=\"https://cdn.prod.website-files.com/666f68e852029a839d204e3c/66b782e636af13b973a460b4_R%26D%20Stats.png\" loading=\"lazy\" class=\"stat-graph\"><p class=\"description\">In the 2006-2021 period, 30.3% of the total private sector R&D expenditures in Türkiye were made by FDI companies.</p></div><div class=\"stats-grid grid\"><div><h5 class=\"h6-heading turqoise right-aligned\"><span class=\"stat-heading\">730</span> TOTAL&nbsp;PATENT APPLICATIONS</h5></div><p class=\"description\">FDI companies applied for an average of 730 patents annually.</p></div><div class=\"stats-grid grid\"><div><h5 class=\"h6-heading turqoise right-aligned\"><span class=\"stat-heading\">6 </span><strong>TIMES INCREASE</strong></h5></div><p class=\"description\">During the 2006-2021 period, the number of FDI companies conducting R&D in Türkiye increased 6-fold.</p></div></div></div>"
            },
            {
                "id": "local-sourcing",
                "iconClass": "turqoise",
                "icon": "",
                "heading": "Local Sourcing",
                "content": "<div id=\"w-node-_0a09421c-a7b6-5fd2-8a2c-c560abd8b29a-bda32aac\" class=\"tab-bento-card\"><div class=\"bento-card-header gap0\"><div class=\"stats-grid grid no-border\"><div id=\"w-node-_0a09421c-a7b6-5fd2-8a2c-c560abd8b29d-bda32aac\" class=\"h6-heading yellow\"><strong>Local Content Ratio</strong></div><img sizes=\"100vw\" srcset=\"https://cdn.prod.website-files.com/666f68e852029a839d204e3c/66b784037f26e84226e42eac_Local%20Content%20Ratio-p-500.png 500w, https://cdn.prod.website-files.com/666f68e852029a839d204e3c/66b784037f26e84226e42eac_Local%20Content%20Ratio.png 793w\" alt=\"\" src=\"https://cdn.prod.website-files.com/666f68e852029a839d204e3c/66b784037f26e84226e42eac_Local%20Content%20Ratio.png\" loading=\"lazy\" class=\"stat-graph\"><p class=\"description\">In the 2006-2021 period, FDI companies made 71.8% of their total supplies locally.</p></div></div></div>"
            }
        ]
    },
    "keyFindingsSection": {
        "sectionId": "key-findings",
        "tagline": "INSIGHTS & FINDINGS",
        "heading": "10 Key Findings Guiding the Strategy",
        "description": "As a result of detailed analyses of the global and regional outlook of FDI flows and the trends shaping this outlook, there emerged 10 key findings that guide the strategy. These findings made significant contributions to the definition of quality FDI profiles for Türkiye and the design of the policy areas put forward within the scope of the strategy.",
        "slides": [
            {
                "id": "slide1",
                "number": "01",
                "heading": "While global FDI flows have remained stagnant, there has been a significant increase in FDI inflows into the region where Türkiye is also located.",
                "description": "Türkiye has emerged as one of the most appealing investment destinations in CEEMENA over the last 20 years. Türkiye is expected to further strengthen its leading position in the coming period.",
                "image": "/images/0-new-rising-trend.avif"
            },
            {
                "id": "slide2",
                "number": "02",
                "heading": "High value-added service sector investments in CEEMENA have been on an upward trend in recent years.",
                "description": "As the leader in medium-high-tech manufacturing investments in CEEMENA, Türkiye is likely to benefit from the opportunities emerging in high value-added service sector investments in the mid-term future.",
                "image": "/images/city-service-network_1.avif"
            },
            {
                "id": "slide3",
                "number": "03",
                "heading": "The fight against climate change and sustainability concerns have become one of the most significant factors influencing global FDI flows.",
                "description": "Green energy, ESG standards, and sustainability are key for attracting international investors. Türkiye can enhance its appeal by improving green transformation, boosting investments in manufacturing and digital sectors.",
                "image": "/images/GlobalFDI-Trend-05_1.webp"
            },
            {
                "id": "slide4",
                "number": "04",
                "heading": "Nearshoring and friend-shoring trends are gaining importance in the transformation of global value chains and these trends deeply affect global FDI.",
                "description": "Türkiye’s production, talent, and market access make it well-positioned for growth. Aligning with international standards and improving the investment environment can boost its role in R&D and design, strengthening its global value chain position.",
                "image": "/images/GlobalFDI-Trend-01.webp"
            },
            {
                "id": "slide5",
                "number": "05",
                "heading": "Rapid growth in digital sectors and digitalization trends in traditional sectors are emerging as significant factors affecting global FDI flows.",
                "description": "Türkiye can boost its market share by enhancing STEM education, improving digital skills, aligning data policies with international standards, and strengthening digital infrastructure.",
                "image": "/images/GlobalFDI-Trend-06_1.webp"
            },
            {
                "id": "slide6",
                "number": "06",
                "heading": "In recent years, protectionist measures, evident in trade and technology conflicts, have begun to affect global FDI flows more profoundly, a trend that has intensified after the pandemic outbreak.",
                "description": "Restrictive policies applied to both inward and outward FDI can create obstacles in many countries, particularly for investors in high-technology sectors. Türkiye, with its investor-friendly environment, can turn this challenge into an opportunity.",
                "image": "/images/GlobalFDI-Trend-02_1.webp"
            },
            {
                "id": "slide7",
                "number": "07",
                "heading": "The number of mega-projects is rapidly increasing on a global scale. CEEMENA in which Türkiye is located is one of the most outstanding destinations for such investments.",
                "description": "Türkiye can attract more mega projects by promoting its incentives, developing large-scale industrial areas, and streamlining bureaucracy.",
                "image": "/images/new-construction.avif"
            },
            {
                "id": "slide8",
                "number": "08",
                "heading": "Türkiye is the top country attracting the most expansion investments in CEEMENA.",
                "description": "Existing investors are expanding their investments in Türkiye, capitalizing on the country's opportunities. Promoting this positive perception globally can attract more greenfield investments. Additionally, retaining current investors is key to encouraging further expansion.",
                "image": "/images/GlobalFDI-Trend-07_1.webp"
            },
            {
                "id": "slide9",
                "number": "09",
                "heading": "International investors play a notable role in making Türkiye an outstanding production and export hub in CEEMENA.",
                "description": "International companies account for about 30% of Türkiye's exports, excelling in medium high-tech but lagging slightly in high-tech exports. Türkiye can boost its high-tech exports by attracting more export-oriented investments.",
                "image": "/images/1-shipment_reduced_1.webp"
            },
            {
                "id": "slide10",
                "number": "10",
                "heading": "Investments in startups have recently become a significant component of global FDI.",
                "description": "Türkiye's tech startups have made significant progress, becoming a top CEEMENA destination for investments. Strengthening the startup ecosystem will attract more high-quality financial FDI.",
                "image": "/images/GlobalFDI-Trend-08_1.webp"
            }
        ]
    },
    "insightHeroSection": {
        "tagline": "FDI STRATEGY",
        "heading": "The Primary Goal and Policy Areas of the Strategy",
        "description": "To achieve these targets, six main policy areas have been identified, considering the global trends affecting FDI and the site selection criteria of international investors."
    },
    "actionPlanHero": {
        "tagline": "FDI STRATEGY",
        "heading": "FDI Action Plans",
        "className": "cover-image action-plans",
        "id": "w-node-_8ef79bb6-13fa-f9cf-d760-5293f3e0cc60-e090c599",
        "paragraph": "Actions include enhancing investment attraction performance in high-quality FDI profiles and setting specific targets for each profile.",
        "downloadButton": {
            "text": "Download Action Plans",
            "link": "/documents/66af9af528fd80c7294a6550_%5BEN%5D%20FDI%20Strategy%20Report%20Reduced.pdf"
        },
        "image": {
            "src": "/images/6673c8c43f29cec166d3ab6b_fdi-action-plans-cover.webp",
            "alt": "cover-visual",
            "srcset": "/images/6673c8c43f29cec166d3ab6b_fdi-action-plans-cover-p-500.webp 500w, /images/6673c8c43f29cec166d3ab6b_fdi-action-plans-cover-p-800.webp 800w, /images/6673c8c43f29cec166d3ab6b_fdi-action-plans-cover.webp 1600w"
        }
    },
    "actionPlanData": {
        "actions": [
            {
                "action_no": "1.1.1",
                "action": "Uluslararası firmaların Ortak Hizmet Merkezlerini (OHM) ülkemize kazandırmak üzere kolaylaştırıcı ve teşvik edici uygulamalar hayata geçirilecektir.",
                "description": "Çok uluslu şirketlerin OHM yatırımlarının ülkemize çekilmesi konusunda mevcut teşvik mekanizmaları haricinde uygulamalar geliştirilecektir.",
                "responsible_institution": "Cumhurbaşkanlığı Yatırım Ofisi",
                "related_institutions": "Sanayi ve Teknoloji Bakanlığı,Ticaret Bakanlığı,YOİKK kapsamındaki STK’lar"
            },
            {
                "action_no": "1.1.2",
                "action": "Uzun vadeli yatırım kredileriyle ilgili stratejik önceliklendirme çalışması yapılacaktır.",
                "description": "Finansman imkânlarına erişimin artırılması kapsamında TCMB Yatırım Taahhütlü Avans Kredisi (YTAK) programında stratejik önceliklendirme yapılacaktır.",
                "responsible_institution": "Türkiye Cumhuriyet Merkez Bankası",
                "related_institutions": "Hazine ve Maliye Bakanlığı,Sanayi ve Teknoloji Bakanlığı,Ticaret Bakanlığı,Strateji ve Bütçe Başkanlığı,YOİKK kapsamındaki Kurum,Kuruluş ve STK’lar"
            },
            {
                "action_no": "1.1.3",
                "action": "Sanayinin yeşil ve dijital dönüşümünü hızlandıracak teşvik mekanizmalarının hayata geçirilecektir.",
                "description": "Ulusal teşvik mekanizmalarının İkiz Dönüşüm eksenli olarak yeniden planlanacaktır.",
                "responsible_institution": "Sanayi ve Teknoloji Bakanlığı",
                "related_institutions": "Hazine ve Maliye Bakanlığı,Ticaret Bakanlığı,Strateji ve Bütçe Başkanlığı,YOİKK kapsamındaki Kurum,Kuruluş ve STK’lar"
            },
            {
                "action_no": "1.2.1",
                "action": "Yatırımcıların faydalanması için uygun yatırım yerlerini içeren yatırım yeri envanteri oluşturulacaktır.",
                "description": "Belirlenecek pilot illerde nitelikli yatırımlara uygun araziler tespit edilecektir.",
                "responsible_institution": "Cumhurbaşkanlığı Yatırım Ofisi",
                "related_institutions": "Çevre,Şehircilik ve İklim Değişikliği Bakanlığı,Sanayi ve Teknoloji Bakanlığı"
            },
            {
                "action_no": "1.2.2",
                "action": "Yatırım yeri tahsis süreçlerinde etkin bir koordinasyon mekanizması kurulacak ve özellikle stratejik ve büyük ölçekli yatırımlarda süreçlerin daha hızlı işlemesini sağlayacak yeni tahsis modelleri geliştirilecektir.",
                "description": "Yatırım yeri tahsis mekanizmaları ile ilgili bürokrasinin azaltılması ve mevzuat güncellemeleri hakkında tespit ve öneri raporu hazırlanacaktır.",
                "responsible_institution": "Cumhurbaşkanlığı Yatırım Ofisi",
                "related_institutions": "Çevre,Şehircilik ve İklim Değişikliği Bakanlığı,Sanayi ve Teknoloji Bakanlığı"
            },
            {
                "action_no": "1.3.1",
                "action": "Teknoloji start-up şirketleri için hisse bazlı uzun vadeli primlerin yaygınlaştırılması amacıyla düzenleme yapılacaktır.",
                "description": "Özellikle teknoloji alanındaki start-up çalışanlarının teşviki amacıyla uzun vadeli prim sistemleri oluşturulacaktır.",
                "responsible_institution": "Hazine ve Maliye Bakanlığı",
                "related_institutions": "Gelir İdaresi Başkanlığı,Sanayi ve Teknoloji Bakanlığı,YOİKK kapsamındaki Kurum,Kuruluş ve STK’lar"
            },
            {
                "action_no": "1.3.2",
                "action": "Girişim Sermayesi Yatırım Fonlarına (GSYF) ilişkin yurt dışı yatırım limiti artırılacaktır.",
                "description": "Girişim Sermayesi Yatırım Fonlarına İlişkin Esaslar Tebliğinin 23’üncü maddesinde yer alan azami %10 oranının değiştirilmesine yönelik düzenleme yapılacaktır.",
                "responsible_institution": "Hazine ve Maliye Bakanlığı",
                "related_institutions": "Sanayi ve Teknoloji Bakanlığı,YOİKK kapsamındaki Kurum,Kuruluş ve STK’lar"
            },
            {
                "action_no": "1.4.1",
                "action": "Yatırım Süreçleri Kılavuzunda belirlenen ve zaman kaybına sebep olan prosedürlerin uygulayıcı kurumlarca en aza indirilmesi sağlanacaktır.",
                "description": "1. Yatırım Süreçleri Kılavuzunda yer alan prosedürlerde sadeleştirilebilecek hususlar özel sektör çatı kuruluşlarıyla işbirliği içinde belirlenecektir.\n2. Dünya Bankası tarafından 180 ülkenin iş ve yatırım ortamını analiz etmek üzere geliştirilen Business Ready Projesi kapsamında yer alacak konulara yönelik olarak reform alanları tespit edilecek ve yıllık eylem planı hazırlanacaktır.",
                "responsible_institution": "Cumhurbaşkanlığı Yatırım Ofisi",
                "related_institutions": "İlgili Kurum ve Kuruluşlar"
            },
            {
                "action_no": "1.5.1",
                "action": "Yatırımcı ile kamu kurumları arasında çıkan yatırım uyuşmazlıklarının tarafların uzlaşması yoluyla en hızlı ve en az maliyetle sonuçlandırılmasını sağlamak üzere alternatif çözüm usulleri geliştirilecektir.",
                "description": "1. Geniş katılımlı bir komisyon kurularak ihtiyaç analizi ve gerekli çalışmalar yapılacaktır.\n2. İdari sulh usulünün etkinleştirilmesine yönelik mevzuat hazırlık çalışmaları tamamlanacaktır.",
                "responsible_institution": "Adalet Bakanlığı",
                "related_institutions": "Sanayi ve Teknoloji Bakanlığı,Cumhurbaşkanlığı Yatırım Ofisi,Strateji ve Bütçe"
            },
            {
                "action_no": "1.5.2",
                "action": "İtiraz, istinaf ve temyiz kanun yollarına başvuru sürelerinin yeknesak hale getirilmesi ve bu sürelerin tebliğden itibaren başlamasına ilişkin düzenleme çalışması yapılacaktır.",
                "description": "Adalet Bakanlığınca yürütülen ilgili mevzuat değişiklikleri çalışması ilgili kurum ve kuruluşların katkısı alınarak tamamlanacaktır.",
                "responsible_institution": "Adalet Bakanlığı",
                "related_institutions": "İçişleri Bakanlığı,Hazine ve Maliye Bakanlığı,Aile ve Sosyal Hizmetler Bakanlığı,Çevre,Şehircilik ve İklim Değişikliği Bakanlığı,Strateji ve Bütçe Başkanlığı,Personel Prensipler Başkanlığı"
            },
            {
                "action_no": "1.6.1",
                "action": "Alanında öncül bilimsel ve teknolojik bilgi üreten ulusal veya uluslararası firmaların Türkiye’de kuracağı Ar-Ge laboratuvarları desteklenecektir.",
                "description": "Öncül Ar-Ge Laboratuvarları Destekleme Programı çerçevesinde THS 1-4 aralığındaki Ar-Ge faaliyetleri desteklenecektir.",
                "responsible_institution": "Sanayi ve Teknoloji Bakanlığı",
                "related_institutions": "TÜBİTAK"
            },
            {
                "action_no": "2.1.1",
                "action": "Hidrojen yakıt hücresi ve bileşenleri ile hidrojen temelli motorlara yönelik yatırım ve teknolojilerin geliştirilmesi çalışmaları yürütülecektir.",
                "description": "1. Yakıt pili modülü (100 kW) üretilecek, farklı uygulama alanları için farklı kapasitelerde modül\/sistem geliştirme çalışmaları yürütülecektir.\n2. Tip Onay Mevzuatı Kapsamında gerekli çalışmalar yürütülecektir.\n3. Yük taşıma araçları için hidrojen depolama, dolum ve yakıt pili sistemlerinin geliştirilmesi çalışmaları yürütülecektir.\n4. Hidrojen Temelli Karbon-Nötr İçten Yanmalı Motor Geliştirilmesi Projesi kapsamında çalışmalar yürütülecektir.",
                "responsible_institution": "Sanayi ve Teknoloji Bakanlığı",
                "related_institutions": "Enerji ve Tabi Kaynaklar Bakanlığı,TÜBİTAK,İlgili Özel Sektör Kuruluşları"
            },
            {
                "action_no": "2.1.2",
                "action": "Yenilenebilir enerji kaynaklarına dayalı ek kapasitelerin kurulabilmesine imkân sağlayacak kanuni değişiklik ihtiyacı TBMM’ye sunulacaktır.",
                "description": "Doğal ve yapay göller üzerine Güneş Enerjisine Dayalı Elektrik Üretim Tesisi (GES) kurulumuna imkân sağlanması amacıyla 3621 sayılı Kıyı Kanununa ilişkin düzenleme ihtiyacı TBMM’ye sunulacaktır.",
                "responsible_institution": "Enerji ve Tabii Kaynaklar Bakanlığı",
                "related_institutions": "Çevre,Şehircilik ve İklim Değişikliği Bakanlığı,Tarım ve Orman Bakanlığı,Strateji ve Bütçe Başkanlığı,YOİKK kapsamındaki Kurum,Kuruluş ve STK’lar"
            },
            {
                "action_no": "2.1.3",
                "action": "Yerli aksam yükümlülüğü olan yeni Yenilenebilir Enerji Kaynak Alanları (YEKA) ihaleleri yapılacak, deniz üstü YEKA projeleri geliştirilmesine yönelik çalışmalar yürütülecektir.",
                "description": "1. En az bir adet YEKA yarışması yapılacaktır.\n2. En az bir adet deniz üstü rüzgâr santrali alanı belirlenecektir.",
                "responsible_institution": "Enerji ve Tabii Kaynaklar Bakanlığı",
                "related_institutions": null
            },
            {
                "action_no": "2.1.4",
                "action": "Yatırımlar (Yenilenebilir Enerji)  için ayrılabilecek Hazine arazileri ülkemizin uzun dönemli yatırım ihtiyaçları çerçevesinde çevresel ve sosyal etkiler ile lojistik gereksinimler dikkate alınarak bölgesel ve sektörel bazda belirlenecektir.",
                "description": "Lisanssız Rüzgar Enerjisi Santrali (RES) ve Güneş Enerjisi Santrali (GES) kurulmak üzere Hazine arazileri ilana çıkarılacaktır.",
                "responsible_institution": "Çevre,Şehircilik ve İklim Değişikliği Bakanlığı",
                "related_institutions": "\"Enerji ve Tabii Kaynaklar\nBakanlığı\",Kültür ve Turizm Bakanlığı,Sanayi ve Teknoloji Bakanlığı"
            },
            {
                "action_no": "2.2.1",
                "action": "Tüm atıkların insan sağlığına ve çevreye olan zararlı etkilerinin en aza indirilebilmesi için havaya, suya ve toprağa salımına ilişkin gerekli tedbirler alınacaktır.",
                "description": "En iyi tekniklerle endüstriyel emisyonların yönetimi ikincil mevzuat hazırlanacaktır.",
                "responsible_institution": "Çevre,Şehircilik ve İklim Değişikliği Bakanlığı",
                "related_institutions": "Sanayi ve Teknoloji"
            },
            {
                "action_no": "2.2.2",
                "action": "Ulusal Döngüsel Ekonomi Eylem Planı hazırlanacaktır.",
                "description": "Ulusal ölçekte döngüsel ekonomi ilkelerinin etkin şekilde uygulanabilmesini teminen eylem planı hazırlanacaktır.",
                "responsible_institution": "Çevre,Şehircilik ve İklim Değişikliği Bakanlığı",
                "related_institutions": "Sanayi ve Teknoloji Bakanlığı,İlgili Kamu Kurum ve Kuruluşları,Yerel Yönetimler"
            },
            {
                "action_no": "2.3.1",
                "action": "Sürdürülebilirlik ve emisyon verilerinin sunumunda standardizasyon sağlanacak, bu veriler paylaşıma açılacaktır.",
                "description": "1. Uluslararası standartlarla uyumlu olacak şekilde Türkiye Sürdürülebilirlik Raporlama Standartları hazırlanacak ve iki sürdürülebilirlik raporlama standardı ile ekli 68 sektöre dayalı sürdürülebilirlik raporlama standardı yayımlanacaktır.\n2. Yatırımcıların ve şirketlerin sürdürülebilirlik risk ve fırsatlarını takip edebilmeleri için Kurumsal Yönetim Tebliğinin ekinde yer alan Sürdürülebilirlik İlkeleri Uyum Çerçevesi uluslararası  standartlarla uyumlu olarak güncellenecektir.",
                "responsible_institution": "Hazine ve Maliye Bakanlığı",
                "related_institutions": "Çevre,Şehircilik ve İklim Değişikliği Bakanlığı"
            },
            {
                "action_no": "2.3.2",
                "action": "Sürdürülebilir ekonomik faaliyetlerin tanımlanmasına ilişkin ulusal taksonomi ve ilgili mevzuat hazırlanacaktır.",
                "description": "1. AB taksonomisi başta olmak üzere, uluslararası taksonomi örnekleriyle uyumlu ve Türkiye’nin ihtiyaçlarını gözeten Ulusal Yeşil Taksonominin oluşturulmasına yönelik mevzuat çalışmaları yapılacaktır.",
                "responsible_institution": "Çevre,Şehircilik ve İklim Değişikliği Bakanlığı",
                "related_institutions": "Hazine ve Maliye Bakanlığı"
            },
            {
                "action_no": "2.3.3",
                "action": "İklim değişikliğiyle ilgili uygulamaların bütüncül bir biçimde ele alındığı temel mevzuat hazırlığı tamamlanacaktır.",
                "description": "İklim mevzuatı hazırlıkları tamamlanacaktır.",
                "responsible_institution": "Çevre,Şehircilik ve İklim Değişikliği Bakanlığı",
                "related_institutions": "İklim Değişikliği Başkanlığı,İlgili Kamu Kurum ve Kuruluşları"
            },
            {
                "action_no": "2.4.1",
                "action": "Sürdürülebilir üretim ve yeşil dönüşüm teknolojilerinin benimsenmesi, geliştirilmesi ve yaygınlaştırılması için Ar-Ge faaliyetleri ve teknoloji transferi desteklenecektir.",
                "description": "Gerçekleştirilecek Türkiye Yeşil Sanayi Projesi kapsamında açılacak Sanayide Yeşil Dönüşüm Çağrısı ve SAYEM Yeşil Dönüşüm Çağrısı ile bu alandaki faaliyetler desteklenecektir.",
                "responsible_institution": "Sanayi ve Teknoloji Bakanlığı",
                "related_institutions": "Çevre,Şehircilik ve İklim Değişikliği Bakanlığı,Enerji ve Tabii Kaynaklar Bakanlığı"
            },
            {
                "action_no": "2.4.2",
                "action": "Yeşil ve dijital dönüşüm için sektörel ihtiyaçlar belirlenerek gerekli yatırımlar teşvik edilecektir.",
                "description": "1. Dijital Dönüşüm Destek Programı kapsamında desteklenecek ürün listesi hazırlanacaktır.\n2. Yeşil ve dijital dönüşüme yönelik yatırımlar öncelikli yatırım konuları arasına alınmış olup başta sektörel ihtiyaçların belirlenmesi olmak üzere gerekli mevzuat çalışmalarının tamamlanması ile yeşil ve dijital dönüşüm yatırımları teşvik edilecektir.",
                "responsible_institution": "Sanayi ve Teknoloji Bakanlığı",
                "related_institutions": "İlgili Kurum ve Kuruluşlar"
            },
            {
                "action_no": "2.5.1",
                "action": "Enerji yoğun sektörler öncelikli olmak üzere rekabetçilik ve yerli üretim dikkate alınarak enerji verimliliği yatırımları desteklenecektir.",
                "description": "1. Enerji verimliliği destek miktarlarının artırılmasına yönelik mevzuat düzenlemesi yapılacaktır.\n2. Verilecek desteklerle enerji verimliliği ve tasarruf potansiyeli yüksek teknolojik yatırımların önceliklendirilmesine yönelik mevzuat düzenlemesi yapılacaktır.",
                "responsible_institution": "Enerji ve Tabii Kaynaklar Bakanlığı",
                "related_institutions": "Sanayi ve Teknoloji Bakanlığı"
            },
            {
                "action_no": "2.5.2",
                "action": "Sıfır kirlilik hedefine yönelik emisyonlarını azaltarak enerji ve kaynak verimli üretim yaptığını raporlayan tesislerin temiz üretim yaptıklarını gösterecek şekilde sertifikalandırılmaları sağlanacaktır.",
                "description": "2010\/75\/AB Endüstriyel Emisyonlar Direktifinin uyumlaştırılması çalışmaları kapsamında AB Entegre Kirlilik Önleme ve Kontrol (EKÖK) Belgesi ile eş nitelikte olacak Sanayide Yeşil Dönüşüm Belgesi sisteminin kurulacaktır.",
                "responsible_institution": "Çevre,Şehircilik ve İklim Değişikliği Bakanlığı",
                "related_institutions": "Enerji ve Tabii Kaynaklar Bakanlığı,Sanayi ve Teknoloji Bakanlığı"
            },
            {
                "action_no": "2.6.1",
                "action": "Dış ticarette rekabetçiliğin korunarak daha ileriye taşınması için ulusal karbon fiyatlandırma mekanizmaları oluşturulacak, öncelikli olarak AB ile uyumlu bir Emisyon Ticaret Sistemi (ETS) uygulamaya konulacaktır.",
                "description": "1. Ulusal karbon fiyatlandırma mekanizması oluşturulacaktır. \n2. AB ile uyumlu bir Emisyon Ticaret Sistemi (ETS) uygulamaya konulacaktır.",
                "responsible_institution": "Çevre,Şehircilik ve İklim Değişikliği Bakanlığı",
                "related_institutions": "Ticaret Bakanlığı"
            },
            {
                "action_no": "2.6.2",
                "action": "Ekonomik faydanın yanı sıra sosyal ve çevresel fayda oluşturulmasına yönelik sosyal etki tahvilleri ve mavi bonolar gibi “etki yatırımı” araçları geliştirilecek ve kullanımı yaygınlaştırılacaktır.",
                "description": "1. Türkiye Kalkınma Fonu nezdinde Etki Fonu kurulacak ve bu kapsamda toplumsal sorun alanlarına sürdürülebilir şekilde fayda temin eden şirketlerin (deprem bölgesindeki firmalar dâhil) desteklenmesi sağlanacaktır.\n2. Sürdürülebilir finansal araçlara yatırım yapan yatırım fonlarına sağlanabilecek vergisel teşvikler konusunda çalışmalar yapılacaktır.",
                "responsible_institution": "Hazine ve Maliye Bakanlığı",
                "related_institutions": null
            },
            {
                "action_no": "3.1.1",
                "action": "Kişisel Verilerin Korunması Kanununun Avrupa Birliği Genel Veri Koruma Tüzüğü (GDPR) başta olmak üzere AB müktesebatına uyum sürecine yönelik çalışmalar tamamlanacaktır.",
                "description": "6698 sayılı Kişisel Verilerin Korunması Kanununun Avrupa Birliği Veri Koruma Tüzüğü (GDPR) ile Uyumlaştırılmasına yönelik çalışmalar tamamlanacaktır.",
                "responsible_institution": "Adalet Bakanlığı",
                "related_institutions": "Sanayi ve Teknoloji Bakanlığı,Ticaret Bakanlığı"
            },
            {
                "action_no": "3.1.2",
                "action": "AB’nin Şebeke ve Bilgi Güvenliği Direktifi (NIS2) ile siber güvenlik alanındaki yeni çalışmaları ve uluslararası alanda en iyi uygulamalar dikkate alınarak ulusal siber güvenliğin sağlanmasına yönelik düzenlemeler yapılacaktır.",
                "description": "1. Ulusal siber güvenliğin sağlanmasına ve siber caydırıcılığın artırılmasına yönelik yönetmelik\nyayımlanacaktır.\n2. Nesnelerin interneti güvenliğine yönelik düzenleme çalışması yapılacaktır.\n3. Nesnelerin interneti cihazlarına yönelik siber güvenlik taramaları gerçekleştirilecektir. \n4. Hazırlık çalışmaları devam eden Ulusal siber güvenlik çatı mevzuatı ile ilişkili olarak ihtiyaç duyulan ikincil düzenlemeler belirlenecektir.\n5. Uzaktan imza hizmetine yasal altyapı teşkil etmek üzere 5070 sayılı Elektronik İmza Kanunu ve ilgili diğer mevzuata dönük çalışmalar yürütülecektir.\n6. Uzaktan imza hizmetinin kamu kurumlarında ve özel sektörde kullanımının yaygınlaştırılması için farkındalık çalışmaları yapılacaktır.",
                "responsible_institution": "\"Ulaştırma ve Altyapı Bakanlığı \nCumhurbaşkanlığı Dijital Dönüşüm Ofisi\"",
                "related_institutions": "Sanayi ve Teknoloji Bakanlığı,BTK,Kişisel Verileri Koruma Kurumu,İlgili Tüm Kamu Kurum ve Kuruluşları"
            },
            {
                "action_no": "3.1.3",
                "action": "6698 sayılı Kişisel Verilerin Korunması Kanununa ilişkin değişiklik ihtiyacı TBMM’ye sunulacaktır.",
                "description": "6698 sayılı Kişisel Verilerin Korunması Kanununun AB Genel Veri Koruma Tüzüğü (GDPR) ile uyumlaştırılmasına yönelik kanuni değişiklik ihtiyacı (Madde 6: Özel nitelikli kişisel verilerin işlenme şartları ve Madde 9: Kişisel verilerin yurt dışına aktarılması) TBMM’ye sunulacaktır.",
                "responsible_institution": "Adalet Bakanlığı",
                "related_institutions": "Dışişleri Bakanlığı,Sanayi ve Teknoloji Bakanlığı,Ticaret Bakanlığı,Strateji ve Bütçe Başkanlığı,Yatırım Ofisi,Dijital Dönüşüm Ofisi,YOİKK kapsamındaki Kurum,Kuruluş ve STK’lar"
            },
            {
                "action_no": "3.2.1",
                "action": "Veri merkezi hizmeti sunan yerli firmaların gelişimini teşvik edecek ve rekabeti etkinleştirecek mekanizmalar oluşturulacaktır.",
                "description": "1. Veri merkezi sektörünün gelişmesine imkân veren düzenleyici çerçeve çalışması yürütülecektir. \n2. Veri merkezi, bulut bilişim ve platform hizmet sektörlerinin geliştirilmesine yönelik mevzuat yürürlüğe konulacaktır.",
                "responsible_institution": "\"Ulaştırma ve Altyapı Bakanlığı \nCumhurbaşkanlığı Dijital Dönüşüm Ofisi\"",
                "related_institutions": "Sanayi ve Teknoloji Bakanlığı,BTK,Kişisel Verileri Koruma Kurumu,TÜBİTAK,Adalet Bakanlığı,Hazine ve Maliye Bakanlığı,Strateji ve Bütçe Başkanlığı"
            },
            {
                "action_no": "3.2.2",
                "action": "5G baz istasyonlarının yerli üretimi desteklenecek, kurulumu yaygınlaştırılarak altyapı yetersizlikleri giderilecektir.",
                "description": "5G'ye yönelik verilen\/verilecek Ar-Ge destekleri belirlenecek ve geliştirilen ürünlerin ticari ve evrensel şebekelerde kullanılacaktır.",
                "responsible_institution": "Ulaştırma ve Altyapı Bakanlığı",
                "related_institutions": "Sanayi ve Teknoloji Bakanlığı"
            },
            {
                "action_no": "3.2.3",
                "action": "Bölgesel farklılıklar dikkate alınarak altyapıların geliştirilemediği bölgelerde genişbant altyapı kurulumu teşvik edilecektir.",
                "description": "Fiber altyapı açısından  desteklenmesi öngörülen yerlerin belirlenmesi, ilgili etki analizi çalışmalarının yapılması, destek ve teşvik yöntemleriyle ilgili alternatiflerin ortaya konulması ve maliyetin tespit edilmesine ilişkin bir rapor hazırlanacaktır.",
                "responsible_institution": "Ulaştırma ve Altyapı Bakanlığı",
                "related_institutions": "BTK"
            },
            {
                "action_no": "3.3.1",
                "action": "5G ve ötesi teknolojilerde sanayi, tarım, ulaşım, enerji gibi dikey sektörlerin gereksinimlerinin uçtan uca karşılanmasına yönelik çalışmalar yapılarak cihaz ve uygulamalar geliştirilecektir.",
                "description": "5G, 6G ve ötesi haberleşme teknolojileri kapsamında BEYOND5 RFSOI Tabanlı Avrupa Tedarik Zincirinin Oluşturulması ve 5G-Mobix projeleri kapsamında planlanan faaliyetler yürütülecektir.",
                "responsible_institution": "Ulaştırma ve Altyapı Bakanlığı",
                "related_institutions": null
            },
            {
                "action_no": "3.3.2",
                "action": "Açık Kaynak Kodlu Yazılım Kümelenmesi kurulacaktır.",
                "description": "1. Açık kaynak kodlu yazılım alanında faaliyet gösteren firma, STK ve üniversite envanteri çıkarılacaktır.\n2. Açık kaynak kodlu yazılım alanında faaliyet gösteren firmalar arasında yatay işbirliğini tesis eden ve rekabet öncesi işbirliği faaliyetlerinin belirleneceği çalıştaylar düzenlenecektir.\n3. Açık kaynak kodlu yazılım kullanımını yaygınlaştırmak üzere eğitim ve farkındalık programları düzenlenecektir.",
                "responsible_institution": "Sanayi ve Teknoloji Bakanlığı",
                "related_institutions": "Cumhurbaşkanlığı Dijital Dönüşüm Ofisi,TÜBİTAK"
            },
            {
                "action_no": "3.3.3",
                "action": "5G ve ötesi yeni nesil iletişim teknolojilerinde yazılım, donanım ve altyapı alanlarında, nesnelerin interneti, yapay zekâ, büyük veri, kuantum, siber güvenlik, akıllı ulaşım, artırılmış gerçeklik gibi gelişen teknoloji alanlarında nitelikli insan gücü yetiştirilmesi çalışmalarına ağırlık verilecektir.",
                "description": "Yapay zekâ, büyük veri ve siber güvenlik alanlarında yükseköğretim programları açılacaktır.",
                "responsible_institution": "YÖK",
                "related_institutions": "Üniversiteler"
            },
            {
                "action_no": "4.1.1",
                "action": "Dijital pazarlarda rekabet ortamının iyileştirilmesine yönelik mevzuat çalışması yapılacaktır.",
                "description": "Dijital pazarlarla ilgili olarak 4054 sayılı Rekabetin Korunması Hakkında Kanuna ilişkin değişiklik ihtiyacı TBMM’ye sunulacaktır.",
                "responsible_institution": "Ticaret Bakanlığı",
                "related_institutions": "Rekabet Kurumu,Dijital Dönüşüm Ofisi,Bilgi Teknolojileri ve İletişim Kurumu,Kişisel Verileri Koruma Kurumu,YOİKK kapsamındaki Kurum,Kuruluş ve STK’lar"
            },
            {
                "action_no": "4.1.2",
                "action": "AB dijital ekonomi düzenlemelerinin ülkemiz ticaretini etkileyen boyutları ve atılması gereken adımlar tespit edilecektir.",
                "description": "AB tarafından son dönemde dijital ekonomiyi ilgilendiren alanlarda oluşturulan mevzuatın ülkemiz açısından ticaret engeli oluşturma riskine karşı önlem alınması amacıyla rapor ve yol haritası oluşturulacaktır.",
                "responsible_institution": "\"Ticaret Bakanlığı\nSanayi ve Teknoloji Bakanlığı\"",
                "related_institutions": "Ulaştırma ve Altyapı Bakanlığı,Dijital Dönüşüm Ofisi,YOİKK kapsamındaki Kurum,Kuruluş ve STK’lar"
            },
            {
                "action_no": "4.2.1",
                "action": "Ülkemizin tercihli ticaret ağı güçlendirilecek, yeni serbest ticaret anlaşmaları müzakere edilecek ve yürürlükteki serbest ticaret anlaşmalarının kapsamının genişletilmesine yönelik çalışmalar yürütülecektir.",
                "description": "1. Türkiye-Japonya STA Müzakereleri sürdürülecektir.\n2. Türkiye-Tayland STA Müzakereleri sürdürülecektir.",
                "responsible_institution": "Ticaret Bakanlığı",
                "related_institutions": "Dışişleri Bakanlığı"
            },
            {
                "action_no": "4.2.2",
                "action": "Gümrük Birliği’nin güncellenmesi müzakereleri yürütülecek ve kamu, özel sektör, STK’lar ve akademisyenlerin görüşleri alınarak ulusal müzakere hazırlık çalışmaları tamamlanacaktır.",
                "description": "Müzakere pozisyonu oluşturmak üzere, kamu, özel sektör, STK’lar ve akademi temsilcilerinin katılımıyla toplantılar düzenlenecektir.",
                "responsible_institution": "Ticaret Bakanlığı",
                "related_institutions": "Dışişleri Bakanlığı,İlgli Kurum ve Kuruluşlar"
            },
            {
                "action_no": "4.3.1",
                "action": "Liman operasyonlarında enerji verimliliğinin artırılması ile çevresel etkilerin en aza indirilmesine yönelik düşük emisyonlu\/emisyon üretmeyen makine ve ekipmanların kullanımı teşvik edilerek yeşil liman uygulamalarının desteklenmesine devam edilecektir.",
                "description": "Kıyı tesislerine yeşil liman sertifikası düzenlenmesi hakkında yönetmelik uygulamaya geçirilecektir.",
                "responsible_institution": "Ulaştırma ve Altyapı Bakanlığı",
                "related_institutions": "Sanayi ve Teknoloji Bakanlığı"
            },
            {
                "action_no": "4.3.2",
                "action": "Tüm ulaşım modlarında, elektrikli veya diğer alternatif yakıtlı araçların kullanımı için altyapı ve teknoloji yatırımları yapılacak, yeni nesil, sürdürülebilir, bütünleşik hareketlilik hizmetleriyle ilgili uyum ve mevzuat düzenlemeleri tamamlanacaktır.",
                "description": "Limanlarda, garlarda ve karayollarında şarj istasyonu enerji altyapıları geliştirilecek, ilgili mevzuat düzenlemeleri tamamlanacaktır.",
                "responsible_institution": "Enerji ve Tabii Kaynaklar Bakanlığı",
                "related_institutions": "Sanayi ve Teknoloji Bakanlığı,Ulaştırma ve Altyapı Bakanlığı"
            },
            {
                "action_no": "4.3.3",
                "action": "Küresel tedarik zincirinin sürdürülebilirliğinin sağlanması için ülkemizin içinde bulunduğu ulaştırma koridorlarının etkinliğini ve çeşitliliğini artırma çalışmaları devam edecektir.",
                "description": "Türk Demiryolu Sektöründe İntermodal Taşımacılık Hizmetlerinin Güçlendirilmesi Projesi tamamlanacaktır.",
                "responsible_institution": "Ulaştırma ve Altyapı Bakanlığı",
                "related_institutions": "Ticaret Bakanlığı"
            },
            {
                "action_no": "4.3.4",
                "action": "Doğu Akdeniz bölgesinde Ortadoğu ve Orta Asya ülkelerinin çıkış kapısı olacak, transit yük odaklı, bölgenin gelişmesine ve ihracatın artırılmasına katkı sağlayacak bir ana liman inşa edilecektir.",
                "description": "Ana liman inşası için yer tespiti ve fizibilite çalışmaları tamamlanacaktır.",
                "responsible_institution": "Ulaştırma ve Altyapı Bakanlığı",
                "related_institutions": "-"
            },
            {
                "action_no": "4.3.5",
                "action": "Yeni sanayi bölgeleri ve lojistik hatları afet riskleri gözetilerek oluşturulacak, mevcut demiryolu altyapısı daha etkin kullanılarak, sanayi bölgeleri ve limanlarla (RORO dâhil) bağlantıları güçlendirilecektir.",
                "description": null,
                "responsible_institution": "Ulaştırma ve Altyapı Bakanlığı",
                "related_institutions": "-"
            },
            {
                "action_no": "4.4.1",
                "action": "Yerli tedarik zincirini güçlendirecek şekilde KOBİ’lerin büyük ölçekli firmalar etrafında kümelenmeleri teşvik edilecektir.",
                "description": "Öncelikli sektörlerde yer alan büyük işletmelerle potansiyel tedarikçilerin bir araya getirilmesi amacıyla yerlileştirilmesi önceliklendirilmiş ürün listelerinin yerli tedarikçilerle üretilmesini sağlamaya yönelik eşleştirme faaliyetleri ile yerli tedarikçilerin sayısının artırılması sağlanacaktır.",
                "responsible_institution": "Sanayi ve Teknoloji Bakanlığı",
                "related_institutions": "-"
            },
            {
                "action_no": "4.4.2",
                "action": "Ana sanayi üreticileri ve büyük işletmelerin tedarik zincirlerine KOBİ’lerin eklemlenebilmeleri için eşleşmeleri kolaylaştırılacak ve gerekli kalite, verim, kapasite artışları ile ürün geliştirme (Ür-Ge) faaliyetleri tedarikçi geliştirme süreçleri kapsamında desteklenecektir.",
                "description": "1. Tedarikçi Dijital Veritabanı işletmelerin yararlanması amacıyla hizmete sunulacaktır.\n2. Tedarikçi Dijital Veritabanı üzerinden yerlileştirme talep eden büyük işletmeler ile bu taleplere cevap veren KOBİ'ler bir araya getirilecektir.\n3. Tedarikçi geliştirme faaliyetleri kapsamında büyük işletme tedarikçilerinin desteklenmesi amacıyla mevcut destek programlarında ilave destek unsurları ve avantajlar sağlanacaktır.\n4. Tedarikçilerin Ar-Ge, Ür-Ge ve inovasyon faaliyetlerini desteklemek amacıyla kurum\/kuruluşlar ile işbirliği yapılacaktır.",
                "responsible_institution": "Sanayi ve Teknoloji Bakanlığı",
                "related_institutions": "Hazine ve Maliye Bakanlığı,Ticaret Bakanlığı"
            },
            {
                "action_no": "4.5.1",
                "action": "Dış ticaret işlemlerinde blokzincir ve otomatik karakter tanıma (OCR) gibi yenilikçi teknolojiler kullanılarak kâğıtsız ortamda gümrük uygulamaları yaygınlaştırılacaktır.",
                "description": "1. BİLGE sisteminde blokzincir (İhracat Zinciri) kullanımına ilişkin çalışmalar yürütülecektir.\n2. İthalatta kâğıtsız gümrük uygulamalarına geçiş için gerekli teknik çalışmalar yapılacaktır.",
                "responsible_institution": "Ticaret Bakanlığı",
                "related_institutions": "-"
            },
            {
                "action_no": "4.5.2",
                "action": "İhracat işlemlerinde blokzincir teknolojisinin kullanılmasına ilişkin yürütülen çalışmaların Bilgisayarlı Gümrük Etkinlikleri (BİLGE) Sistemini de kapsayacak şekilde geliştirilmesi sağlanacaktır.",
                "description": "İhracat işlemleriyle ilgili tüm gümrük ve lojistik süreçlerinin blokzincir altyapısı kullanılarak süreçteki tüm paydaşlarca elektronik ortamda tek bir ekrandan yürütülebilmesi ve izlenebilmesine sağlayacak İhracat Zinciri Projesi hayata geçirilecektir.",
                "responsible_institution": "Ticaret Bakanlığı",
                "related_institutions": "-"
            },
            {
                "action_no": "4.6.1",
                "action": "Öncelikli sektörler başta olmak üzere firma iş ortaklıkları yüksek teknolojili Ür-Ge ve ticarileştirmesinin desteklendiği Sanayi Yenilik Ağ Mekanizması Programı yaygınlaştırılacaktır.",
                "description": "Türkiye Yeşil Sanayi Projesi kapsamında açılacak, Sanayide Yeşil Dönüşüm Çağrısı ve SAYEM Yeşil Dönüşüm Çağrısı ile bu alandaki faaliyetler desteklenecektir.",
                "responsible_institution": "Sanayi ve Teknoloji Bakanlığı",
                "related_institutions": "TÜBİTAK,Üniversiteler"
            },
            {
                "action_no": "5.1.1",
                "action": "Uzaktan ya da yerinde yurt dışına çalışmakta olan yazılımcıların yerli bilişim sektörünün katma değerini artıracak şekilde istihdam edilebilmeleri için önlemler alınacaktır.",
                "description": "Üniversitelerin ilgili bölümlerinden yeni mezun olan ve belirli bir süre aynı şirkette çalışan kişilere yönelik yeni yazılımcı teşviki uygulaması hayata geçirilecektir.",
                "responsible_institution": "Sanayi ve Teknoloji Bakanlığı",
                "related_institutions": "Hazine ve Maliye Bakanlığı Cumhurbaşkanlığı Dijital Dönüşüm Ofisi,Cumhurbaşkanlığı İnsan Kaynakları Ofisi"
            },
            {
                "action_no": "5.1.2",
                "action": "İkiz Dönüşüm kapsamında ihtiyaç duyulan mesleklerde becerilerin belirlenmesi ve belirlenen becerileri dikkate alan eğitim içerikleri\/modüllerinin geliştirilmesi, işbaşı eğitim programı ve mesleki eğitim kurslarının gerçekleştirilmesi",
                "description": "1. İkiz Dönüşüm kapsamında ihtiyaç duyulan mesleklerde becerilerin ortak çalışma yapılarak belirlenmesi\n2. İkiz Dönüşüm kapsamında ilgili mesleklerde belirlenen becerileri dikkate alan eğitim içerikleri\/modüllerinin YÖK ile koordine edilerek geliştirilmesi\n3. Geliştirilen eğitim modüllerini dikkate alarak işbaşı eğitim programı ve mesleki eğitim kurslarının TİSK ve TOBB iş birliğinde gerçekleştirilmesi\n",
                "responsible_institution": "Çalışma ve Sosyal Güvenlik Bakanlığı",
                "related_institutions": "İŞKUR"
            },
            {
                "action_no": "5.1.3",
                "action": "Ar-Ge ve Tasarım Merkezleri ile Teknoloji Geliştirme Bölgelerinde uzaktan çalışma uygulaması devam ettirilecektir.",
                "description": "Ar-Ge ve Tasarım Merkezleri ile Teknoloji Geliştirme Bölgesi çalışanlarına yönelik sağlanan uzaktan çalışma hakkının, özellikle dijital teknolojilerde çalışanlar için sürekli olarak %100 uzaktan çalışma olacak şekilde devamlılığı sağlanacaktır.",
                "responsible_institution": "Sanayi ve Teknoloji Bakanlığı",
                "related_institutions": "Hazine ve Maliye Bakanlığı,Strateji ve Bütçe Başkanlığı,YOİKK kapsamındaki Kurum,Kuruluş ve STK’lar"
            },
            {
                "action_no": "5.2.1",
                "action": "Araştırma deneyimine sahip nitelikli insan kaynağının artırılmasını teminen kamu destekli Ar-Ge projelerinde daha fazla lisans, yüksek lisans ve doktora bursiyerinin yer alması sağlanacaktır.",
                "description": "1. Doktora öğrencilerinin Ar-Ge projelerinde yer almasının teşvik edilmesi amacı ile Lisansüstü Bursları Performans Programı yılda iki kez çağrıya açılarak başvuru ve destek süreçleri yürütülecektir.\n2. Stajyer Araştırmacı Burs Programı kapsamında yılda iki defa çağrıya çıkılacak ve program kapsamında desteklenen bursiyerlerin projelerde yer alması sağlanacaktır.",
                "responsible_institution": "Sanayi ve Teknoloji Bakanlığı",
                "related_institutions": "YÖK,TÜBİTAK,Üniversiteler"
            },
            {
                "action_no": "5.2.2",
                "action": "Üst seviye bilimsel ve teknolojik çalışmalar yürüten nitelikli araştırmacıların özel programlarla desteklenmesine devam edilecektir.",
                "description": "1. Uluslararası Lider\/Genç Araştırmacılar Programları çağrıya açılarak başvuru, destek ve izleme süreçleri yürütülecektir.\n2. Ulusal Lider Araştırmacılar Programı ve Ulusal Genç Liderler Programları çağrıya açılarak başvuru, destek ve izleme süreçleri yürütülecektir.",
                "responsible_institution": "Sanayi ve Teknoloji Bakanlığı",
                "related_institutions": "YÖK,TÜBİTAK,Üniversiteler"
            },
            {
                "action_no": "5.2.3",
                "action": "Sanayide ihtiyaç duyulan doktora derecesine sahip nitelikli insan kaynağının üniversite sanayi işbirliğiyle yetiştirilmesi sağlanacak ve doktoralı araştırmacı istihdamı teşvik edilecektir.",
                "description": "Sanayi Doktora Programı kapsamında doktora öğrencileri desteklenecek ve sanayide doktoralı araştırmacı istihdamı teşvik edilecektir.",
                "responsible_institution": "Sanayi ve Teknoloji Bakanlığı",
                "related_institutions": "YÖK,TÜBİTAK,Üniversiteler"
            },
            {
                "action_no": "5.2.4",
                "action": "Temel bilimlere yönelik lisansüstü burs miktarı ve faydalanan sayısı artırılacaktır.",
                "description": "Temel Bilimlerde Genç Beyinler Projesi ile devlet üniversitelerinde fizik, kimya, biyoloji ve matematik alanlarından birinde doktora eğitiminin tez aşamasında olan araştırma görevlilerine burs desteği verilerek yurt dışında araştırma yapma imkânı sağlanacaktır.",
                "responsible_institution": "YÖK",
                "related_institutions": "Üniversiteler"
            },
            {
                "action_no": "5.3.1",
                "action": "Yabancı dilde uluslararası standartlarda eğitim verilerek öğrencilerin ileri düzeyde okuma, anlama, konuşma ve yazma becerilerini edinmeleri sağlanacaktır.",
                "description": "1. Yabancı dil eğitimine yönelik politikalar güncel yaklaşımlarla uyumlu olarak ele alınarak dinleme, konuşma, okuma ve yazma alanlarındaki beceriler geliştirilecek ve öğretim programlarında kullanılan dil yeterlik çerçevesi ile ilgili hizmet içi eğitimler yapılarak öğretmenlerin bilgi ve becerileri artırılacaktır.\n2. Öğrencilerin yabancı dil becerilerinin geliştirilmesi için ortaokul beşinci sınıflarda uygulanan yabancı dil hazırlık sınıfı İngilizce ve Almanca olarak yapılandırılacak ve belirlenen okullarda 2023-2024 eğitim öğretim yılında pilot uygulama yapılacaktır.\n3. Yabancı dil eğitimine ilişkin materyaller zenginleştirilecek, dinleme, konuşma, okuma ve yazma alanlarındaki becerilerin tümünü ölçmeye yönelik sistem geliştirilecektir.",
                "responsible_institution": "Milli Eğitim Bakanlığı",
                "related_institutions": "-"
            },
            {
                "action_no": "5.4.1",
                "action": "Mesleki ve teknik öğretim kurumları ile Organize Sanayi Bölgeleri arasında iş birliği protokolleri oluşturulacaktır.",
                "description": "1. OSB’lerin kuruluş aşamasında pansiyonlu mesleki ve teknik ortaöğretim kurumu ile birlikte planlanması ve bu konunun standart hale gelmesi için çalışma yürütülecektir.\n2. Henüz bünyesinde mesleki ve teknik ortaöğretim kurumu bulunmayan OSB’lerde Millî Eğitim Bakanlığı ile iş birliği içinde pansiyonlu okul binaları yapılacak, yapılan okullar Millî Eğitim Bakanlığı ya da OSB yönetimine bağlı olarak faaliyet gösterecektir.",
                "responsible_institution": "\"Millî Eğitim Bakanlığı \nSanayi ve Teknoloji Bakanlığı\"",
                "related_institutions": "Organize Sanayi Bölgeleri Üst Kuruluşu,Sanayi ve Ticaret Odaları,YOİKK kapsamındaki Kurum,Kuruluş ve STK’lar"
            },
            {
                "action_no": "5.4.2",
                "action": "Mesleki ve teknik öğretim kurumları ile Sanayi ve Ticaret Odaları ve Organize Sanayi Bölgeleri yönetimleri başta olmak üzere özel sektör kurumlarıyla eşleştirmeler yapılacak ve mesleki eğitim alanında sektörel kümelenmeler sağlanacaktır.",
                "description": "1. Mesleki ve teknik ortaöğretim okullar ile OSB’ler eşleştirilecek ve eşleştirilen okullar OSB’ler tarafından öğrencilerin beceri eğitimi, mezunların istihdamı ve öğretmenlerin iş başında mesleki gelişiminin sağlanması bakımından desteklenecektir.\n2. Özellikle Sanayi ve Ticaret Odalarıyla ve Organize Sanayi Bölgeleri yönetimleriyle birlikte örnek pilot uygulamalar hayata geçirilecek ve örnek uygulamalara dayalı eşleştirme modeli yaygınlaştırılacaktır.",
                "responsible_institution": "\"Millî Eğitim Bakanlığı \nSanayi ve Teknoloji Bakanlığı\"",
                "related_institutions": "Organize Sanayi Bölgeleri Üst Kuruluşu,Sanayi ve Ticaret Odaları,YOİKK kapsamındaki Kurum,Kuruluş ve STK’lar"
            },
            {
                "action_no": "5.5.1",
                "action": "İlkaşama girişimcileri için vize modelinin oluşturulması sağlanarak yurt dışındaki girişimcilerin Türkiye’ye gelmesini cazip kılacak destek ve düzenlemeler yapılacaktır.",
                "description": "İlkaşama girişimcileri için vize modeline yönelik faaliyetler sürdürülerek gerekli destek ve düzenlemeler hayata geçirilecektir.",
                "responsible_institution": "Çalışma ve Sosyal Güvenlik Bakanlığı",
                "related_institutions": "İçişleri Bakanlığı,Sanayi ve Teknoloji Bakanlığı"
            },
            {
                "action_no": "5.5.2",
                "action": "Ar-Ge veya Ür-Ge faaliyetleri yürütülmesi, yatırımın yapılması, yönetilmesi veya işletilmesiyle ilgili olarak ülkeye gelecek diğer ülke vatandaşlarına ve ailelerine ikamet ve çalışma izni verilmesinde öncelik ve kolaylık sağlanacaktır.",
                "description": "Teknolojik dönüşüm ve dijitalleşme sürecinin getirdiği uzaktan çalışma şeklinin sektörel, mesleki ve bölgesel işgücü göçü bağlamında etkilerinin analiz edilmesine yönelik araştırma çalışması yapılacaktır.",
                "responsible_institution": "Çalışma ve Sosyal Güvenlik Bakanlığı",
                "related_institutions": "İçişleri Bakanlığı,Sanayi ve Teknoloji Bakanlığı"
            },
            {
                "action_no": "5.5.3",
                "action": "Nitelikli yabancı uyruklu doktoralı araştırmacı ve akademisyenlerin istihdamını özendirici ve kolaylaştırıcı çalışmalar yürütülecektir.",
                "description": "İhtiyaç duyulan alanlarda yabancı uyruklu doktoralı uluslararası bilim insanlarının istihdamı desteklenecektir.",
                "responsible_institution": "YÖK",
                "related_institutions": "Üniversiteler"
            },
            {
                "action_no": "5.6.1",
                "action": "Mevcut işgücünün becerilerinin geliştirilmesi desteklenecek, işgücünde yer almayanların sanayinin ihtiyaç duyduğu becerilerle donatılması kamu-özel işbirliğiyle sağlanacaktır.",
                "description": "Sanayi sektöründe emek arzı ile işgücü piyasası ihtiyaçları arasında uyumu güçlendirmeye yönelik aktif işgücü piyasası programları kapsamında 40 bin kişiye yönelik mesleki eğitim kursu ve işbaşı eğitim programı düzenlenecektir.",
                "responsible_institution": "Çalışma ve Sosyal Güvenlik Bakanlığı",
                "related_institutions": "Sanayi ve Teknoloji Bakanlığı"
            },
            {
                "action_no": "5.6.2",
                "action": "Eğitimde ve istihdamda olmayan gençlerin istihdama erişimlerini desteklemeye yönelik programların etkinliği artırılacaktır.",
                "description": "İŞKUR tarafından gençlere yönelik olarak verilen hizmetleri tek bir çatı altında toplamak ve gençlerin söz konusu hizmetleri tek bir platformdan takip etmelerine imkân sağlamayı amaçlayan İŞKUR gençlik internet sitesi uygulamaya açılarak kullanımı yaygınlaştırılacaktır.",
                "responsible_institution": "Çalışma ve Sosyal Güvenlik Bakanlığı",
                "related_institutions": "İŞKUR,Milli Eğitim Bakanlığı"
            },
            {
                "action_no": "5.6.3",
                "action": "Merkezi yönetim, yerel yönetimler, özel sektör ve STK’larla işbirliğiyle kadın istihdamını artırmak üzere kaliteli, ekonomik ve erişilebilir bakım hizmetlerine ilişkin alternatif modeller geliştirilmesi ve pilot uygulamaları takiben yaygınlaştırılması sağlanacaktır.",
                "description": "Merkezi yönetim, yerel yönetimler, özel sektör ve STK’larla işbirliğiyle kadın istihdamını artırmak üzere kaliteli,  ekonomik ve erişilebilir bakım hizmetlerine ilişkin alternatif\nmodeller geliştirilmesi konusunda çalışmalar yapılacaktır.",
                "responsible_institution": "Aile ve Sosyal Hizmetler Bakanlığı",
                "related_institutions": "Çalışma ve Sosyal Güvenlik Bakanlığı,Mahalli İdareler,STK’lar,Özel Sektör Kuruluşları"
            },
            {
                "action_no": "6.1.1",
                "action": "Türkiye yatırım ortamının ulusal ve uluslararası ölçekte tanıtımı için etkinlikler düzenlenecek ve önde gelen etkinliklere katılım sağlanacaktır.",
                "description": "-",
                "responsible_institution": "Cumhurbaşkanlığı Yatırım Ofisi",
                "related_institutions": "-"
            },
            {
                "action_no": "6.1.2",
                "action": "Türkiye yatırım ortamının etkin bir şekilde tanıtılması için yeni bir iletişim ve tanıtım stratejisi hazırlanıp uygulanacaktır.",
                "description": "-",
                "responsible_institution": "Cumhurbaşkanlığı Yatırım Ofisi",
                "related_institutions": "-"
            },
            {
                "action_no": "6.1.3",
                "action": "Konvansiyonel, dijital ve sosyal mecralarda iletişim ve tanıtma faaliyetleri için özel içerikler üretilecek ve dil seçenekleri artırılacaktır.",
                "description": "-",
                "responsible_institution": "Cumhurbaşkanlığı Yatırım Ofisi",
                "related_institutions": "-"
            },
            {
                "action_no": "6.2.1",
                "action": "invest.gov.tr kurumsal internet sitesi, Türkiye'nin uluslararası doğrudan yatırım ortamı hakkında değer önerisini tanıtmak ve yatırımcıları fırsatlara yönlendirmek için ana platform olarak tekrar tasarlanacak ve kullanıma açılacaktır.",
                "description": "-",
                "responsible_institution": "Cumhurbaşkanlığı Yatırım Ofisi",
                "related_institutions": "-"
            },
            {
                "action_no": "6.2.2",
                "action": "“Invest in Türkiye” markasının yeniden konumlandırılması için kurumsal kimlik öğeleri güncellenecektir.",
                "description": "-",
                "responsible_institution": "Cumhurbaşkanlığı Yatırım Ofisi",
                "related_institutions": "-"
            },
            {
                "action_no": "6.3.1",
                "action": "Nitelikli UDY profilleri özelinde, öncelikli ülke ve bölgelerde sektörel tanıtım etkinlikleri düzenlenecek ve önde gelen etkinliklere katılım sağlanacaktır.",
                "description": "-",
                "responsible_institution": "Cumhurbaşkanlığı Yatırım Ofisi",
                "related_institutions": "-"
            },
            {
                "action_no": "6.3.2",
                "action": "Nitelikli UDY profilleri özelinde Türkçe ve ihtiyaç duyulan yabancı dillerde sektör raporları hazırlanacak ve bu raporlar her yıl güncellenecektir.",
                "description": "-",
                "responsible_institution": "Cumhurbaşkanlığı Yatırım Ofisi",
                "related_institutions": "-"
            },
            {
                "action_no": "6.3.3",
                "action": "Nitelikli UDY profillerinde Türkiye'deki yatırım fırsatlarının etkin tanıtımı için ana mesajlar ve özgün içerikler (message house) kurgulanacak, dinamik stratejiler geliştirilecektir.",
                "description": "-",
                "responsible_institution": "Cumhurbaşkanlığı Yatırım Ofisi",
                "related_institutions": "-"
            },
            {
                "action_no": "6.3.4",
                "action": "Uluslararası yatırımcılara yönelik yatırım yapılmaya hazır projelerin yer alacağı bir portföy (KÖİ, sanayi işbirliği projeleri, M&A yatırımları vb.) oluşturulacaktır.",
                "description": null,
                "responsible_institution": "Cumhurbaşkanlığı Yatırım Ofisi",
                "related_institutions": "-"
            },
            {
                "action_no": "6.4.1",
                "action": "Ülkemizin teknoloji girişimi (startup) ekosisteminin ve büyüme potansiyeli yüksek teknoloji girişimlerinin uluslararası ölçekte tanıtımı yapılacaktır.",
                "description": "-",
                "responsible_institution": "Cumhurbaşkanlığı Yatırım Ofisi",
                "related_institutions": "-"
            },
            {
                "action_no": "6.4.2",
                "action": "Her bir nitelikli UDY profili kapsamında 2024-28 dönemi için potansiyel yatırım alanları ve ülke\/bölge özelinde potansiyel yatırımcı şirketler tespit edilecek, bu şirketlere yönelik yatırım çekme faaliyetleri yürütülecektir.",
                "description": "-",
                "responsible_institution": "Cumhurbaşkanlığı Yatırım Ofisi",
                "related_institutions": "-"
            },
            {
                "action_no": "6.4.3",
                "action": "Türkiye'de faaliyet gösteren UDY firmalarının ülkemizdeki yatırımlarını daha katma değerli alanlara (ar-ge ve tasarım merkezi, bölgesel yönetim merkezi, ortak hizmet merkezi, bölgesel dağıtım merkezi vb.) genişletmesi için hedef odaklı yatırım çekme faaliyetleri yürütülecektir.",
                "description": "-",
                "responsible_institution": "Cumhurbaşkanlığı Yatırım Ofisi",
                "related_institutions": "-"
            },
            {
                "action_no": "6.5.1",
                "action": "Uluslararası şirketlerde görev yapan Türk yöneticiler ve yurt dışında yerleşik Türk girişimciler ile iş birliği ve istişare mekanizmaları geliştirilecek ve ülkemizin yatırım ortamının tanıtılmasında ortak faaliyetler yürütülecektir.",
                "description": "-",
                "responsible_institution": "Cumhurbaşkanlığı Yatırım Ofisi",
                "related_institutions": "-"
            },
            {
                "action_no": "6.5.2",
                "action": "Ülkemizde faaliyet gösteren uluslararası yatırımcıların olumlu tecrübelerini ve Türkiye’nin değer önerilerini aktaran içerikler hazırlanarak tanıtma faaliyetlerinde kullanılacaktır.",
                "description": "-",
                "responsible_institution": "Cumhurbaşkanlığı Yatırım Ofisi",
                "related_institutions": "-"
            },
            {
                "action_no": "6.5.3",
                "action": "Türkiye UDY Stratejisi ve nitelikli UDY profilleri yatırım ekosisteminde yer alan önemli paydaşlara (büyükelçilikler, ticaret müşavirlikleri, kalkınma ajansları ve ilgili diğer kamu kurumları vb.) tanıtılacaktır.",
                "description": "-",
                "responsible_institution": "Cumhurbaşkanlığı Yatırım Ofisi",
                "related_institutions": "-"
            },
            {
                "action_no": "6.5.4",
                "action": "Türkiye’de yerleşik iş örgütlerine, uluslararası kuruluşlara, medya kuruluşlarına ve gazetecilere yönelik iletişim ve iş birliği programları geliştirilecektir.",
                "description": "-",
                "responsible_institution": "Cumhurbaşkanlığı Yatırım Ofisi",
                "related_institutions": "-"
            }
        ],
        "policies": [
            {
                "policy_no": "1.1",
                "policy": "Türkiye ekonomisinin ikiz (yeşil ve dijital) dönüşümünü destekleyecek nitelikli UDY projelerinin çekilebilmesi için yatırımcılara sunulan teşvik mekanizmalarının etkinliği artırılacaktır."
            },
            {
                "policy_no": "1.2",
                "policy": "Nitelikli UDY profillerinin ihtiyaç duyduğu yatırım yerlerinin çevresel ve sosyal etkiler ile lojistik gereksinimler de dikkate alınarak geliştirilmesi, dijital ortamlarda sunulması ve arazi tahsis süreçlerinin kolaylaştırılması sağlanacaktır."
            },
            {
                "policy_no": "1.3",
                "policy": "Teknoloji girişimciliği ekosisteminin geliştirilmesi amacıyla gerekli fiziksel, hukuki ve dijital altyapı  güçlendirilecek, alternatif ve yenilikçi finansman imkânlarının artırılması sağlanacaktır."
            },
            {
                "policy_no": "1.4",
                "policy": "Nitelikli UDY projelerinin hızla hayata geçmesini teminen bürokratik süreçlerin kolaylaştırılması, dijitalleştirilmesi ve maliyetlerin azaltılması sağlanacaktır."
            },
            {
                "policy_no": "1.5",
                "policy": "İş ve yatırım ortamında düzenleyici çerçeve iyileştirilerek yatırımcı güveni, öngörülebilirlik ve istikrar artırılacaktır."
            },
            {
                "policy_no": "1.6",
                "policy": "Ülkemizdeki uluslararası yatırımcıların faaliyetlerinin derinleştirilmesi, Ar-Ge ve tasarım gibi daha katma değerli alanlara yöneltilmesi ve genişleme türü yatırımlarının artırılması için çalışmalar yürütülecektir."
            },
            {
                "policy_no": "2.1",
                "policy": "Yenilenebilir enerji üretiminin artırılması, iklim ve çevre dostu teknolojilerin yaygınlaştırılması ve yatırımcıların yeşil enerjiye erişiminin kolaylaştırılması sağlanacaktır."
            },
            {
                "policy_no": "2.2",
                "policy": "Ülkemizin yeşil dönüşüm sürecini destekleyecek nitelikli UDY projelerinin çekilebilmesi için döngüsel ekonomi ve sürdürülebilirlik odaklı yatırım ortamı iyileştirme çalışmaları yürütülecektir."
            },
            {
                "policy_no": "2.3",
                "policy": "Başta Avrupa Yeşil Mutabakatı olmak üzere ülkemizin yeşil dönüşüm sürecini destekleyecek uluslararası standartlar ve düzenlemeler takip edilerek üretim ve ticarete yönelik gerekli düzenlemeler hayata geçirilecektir."
            },
            {
                "policy_no": "2.4",
                "policy": "Nitelikli UDY projelerini çekmek amacıyla düşük emisyonlu ve dijital üretim teknikleriyle bütünleşik, çevre dostu politikalar hayata geçirilecektir."
            },
            {
                "policy_no": "2.5",
                "policy": "Enerji verimliliği ve tasarruf potansiyeli yüksek teknolojik yatırımların ülkemize çekilebilmesi için uygun yatırım ortamı sağlanacaktır."
            },
            {
                "policy_no": "2.6",
                "policy": "Türkiye’de yeşil finansmanın gelişimini sağlayacak ve ekosistemin güçlendirilmesine yönelik çalışmalar yürütülecektir."
            },
            {
                "policy_no": "3.1",
                "policy": "Ülkemizin dijital dönüşüm sürecini destekleyecek uluslararası standartlar, veri politikaları ve yasal düzenlemeler takip edilerek üretim ve ticarete yönelik gerekli düzenlemeler hayata geçirilecektir."
            },
            {
                "policy_no": "3.2",
                "policy": "Nitelikli UDY projelerinin ülkemize kazandırılması için ülkemizin fiziksel dijital altyapısı güçlendirilecektir."
            },
            {
                "policy_no": "3.3",
                "policy": "5G ve ötesi yeni nesil iletişim teknolojileri, nesnelerin interneti, yapay zekâ, büyük veri, kuantum, siber güvenlik, blok zincir, akıllı ulaşım, artırılmış gerçeklik gibi gelişen teknoloji alanlarında ekosistemin geliştirilmesi sağlanacaktır."
            },
            {
                "policy_no": "4.1",
                "policy": "Ülkemizin küresel değer zincirlerine entegrasyonunun güçlendirilmesi için yeşil ve dijital dönüşüm alanında uluslararası ticaret politikalarına uyum seviyesi artırılacaktır."
            },
            {
                "policy_no": "4.2",
                "policy": "Ülkemizde ve dünya ticaretinde yaşanan ekonomik gelişmeler göz önünde bulundurularak ülkemizin taraf olduğu ikili ve çok taraflı anlaşmaların (Gümrük Birliği, serbest ticaret anlaşmaları, çifte vergilendirmeyi önleme anlaşmaları vb.) güncellenmesine ve yeni anlaşmaların hayata geçirilmesine yönelik çalışmalar yürütülecektir."
            },
            {
                "policy_no": "4.3",
                "policy": "Türkiye’nin küresel değer zincirlerindeki konumunun güçlendirilmesi amacıyla tüm ulaşım modlarında lojistik kabiliyetleri ve altyapısı çevre ve yeşil dönüşüm hassasiyetleri dikkate alınarak geliştirilecektir."
            },
            {
                "policy_no": "4.4",
                "policy": "Türkiye’deki mevcut uluslararası yatırımcılar ile yerel tedarikçi firmaların iş birlikleri geliştirilerek yerel firmaların küresel değer zincirlerine entegrasyonu artırılacaktır."
            },
            {
                "policy_no": "4.5",
                "policy": "Gümrük işlemlerinde etkinlik ve verimliliğin artırılması amacıyla bilgi ve iletişim teknolojilerinden azami ölçüde yararlanılacak, gümrük hizmetleri yeşil ve dijital dönüşümle uygun bir yapıya kavuşturulacaktır."
            },
            {
                "policy_no": "4.6",
                "policy": "İmalat sektöründeki katma değeri artırmaya yönelik Ar-Ge, Ür-Ge, tasarım, tedarik zinciri yönetimi, dağıtım ve bakım-onarım gibi nitelikli hizmet girdilerinin payının artırılmasına yönelik çalışmalar yapılacaktır."
            },
            {
                "policy_no": "5.1",
                "policy": "Türkiye’nin ikiz dönüşüm çerçevesinde ihtiyaç duyduğu nitelikli beşeri sermayenin geliştirilmesi sağlanacak ve geleceğin mesleklerinin ortaya çıkardığı yeni çalışma modellerine uygun mevzuat çalışmaları yapılacaktır."
            },
            {
                "policy_no": "5.2",
                "policy": "STEM (fen, teknoloji, mühendislik ve matematik) alanlarında eğitim ve istihdamın artırılmasını sağlayacak programlar geliştirilecek ve nitelikli UDY profillerinin ihtiyaç duyduğu yetkin Ar-Ge personelinin yetiştirilmesi sağlanacaktır."
            },
            {
                "policy_no": "5.3",
                "policy": "Nitelikli UDY profillerinin ihtiyaç duyduğu insan kaynağının mesleğe özgü yabancı dil becerilerinin geliştirilmesine yönelik çalışmalar yürütülecektir."
            },
            {
                "policy_no": "5.4",
                "policy": "Nitelikli UDY profillerinin ihtiyaç duyduğu teknik insan kaynağının yetiştirilmesi amacıyla mesleki eğitim kalitesinin ve itibarının artırılmasına yönelik çalışmalar yapılacak; mesleki ve teknik eğitim alan bireylerin işgücü piyasasına geçişleri kolaylaştırılacaktır."
            },
            {
                "policy_no": "5.5",
                "policy": "Türkiye’nin insan kaynakları alanında uluslararası görünürlüğünün artırılması, yurtdışındaki nitelikli insan kaynağının ülkeye çekilmesi ve mevcut yeteneklerin elde tutulması için hedef odaklı çalışmalar yürütülecektir."
            },
            {
                "policy_no": "5.6",
                "policy": "İşgücüne dâhil olmayan nüfusun ekonomide aktif hale gelmesi amacıyla kadın ve gençler başta olmak üzere işgücüne katılımın teşvik edilmesine yönelik çalışmalar yürütülecektir."
            },
            {
                "policy_no": "6.1",
                "policy": "360 derece iletişim yaklaşımı benimsenerek yatırım diplomasisi, uluslararası kampanyalar ve medya ilişkileri vb. gibi araçlarla ülkemizin yatırım ortamı aktif bir şekilde tanıtılacaktır."
            },
            {
                "policy_no": "6.2",
                "policy": "“Invest in Türkiye” markasının güçlendirilmesine, hedef kitlelere yönelik etkili mesajların “Türkiye Yüzyılı, yatırımların Yüzyılı” vizyonuna uygun şekilde geliştirilmesine ve etkili iletişiminin yapılmasına yönelik çalışmalar yapılacaktır."
            },
            {
                "policy_no": "6.3",
                "policy": "Ülkemizdeki yatırım fırsatlarını tanıtan özelleştirilmiş içerikler, materyaller, hedef kitleye yönelik etkinlikler ve dijital kampanyalar yoluyla nitelikli UDY profilleri özelinde tanıtım faaliyetleri yürütülecektir."
            },
            {
                "policy_no": "6.4",
                "policy": "Strateji kapsamında tanımlanan nitelikli UDY profillerine uygun hedef yatırımcılar belirlenerek bu yatırımcılara yönelik yatırım çekme faaliyetleri yürütülecektir."
            },
            {
                "policy_no": "6.5",
                "policy": "Ulusal ve uluslararası tüm paydaşlarla stratejik iletişim çerçevesinde iş birlikleri geliştirilerek ülkemizin iletişim ve tanıtım faaliyetleri güçlendirilecektir."
            }
        ],
        "policy_areas": [
            {
                "area_no": "1",
                "policy_area": "Yatırım Ortamı Rekabetçiliği",
                "imgSrc": "/images/66b262cebe6aa1fd8866d805_qualified_investment.png"
            },
            {
                "area_no": "2",
                "policy_area": "Yeşil Dönüşüm",
                "imgSrc": "/images/66b262e1378812f97c95e929_05.png"
            },
            {
                "area_no": "3",
                "policy_area": "Dijital Dönüşüm",
                "imgSrc": "/images/66b262f88aeec805f8c89cee_08.png"
            },
            {
                "area_no": "4",
                "policy_area": "Küresel Tedarik Zinciri",
                "imgSrc": "/images/66b263055dc4a30bfe69fee9_01.png"
            },
            {
                "area_no": "5",
                "policy_area": "Nitelikli İnsan Kaynağı",
                "imgSrc": "/images/66b2631df1f52b5ef87174d1_new-centers2.png"
            },
            {
                "area_no": "6",
                "policy_area": "İletişim ve Tanıtma",
                "imgSrc": "/images/66b263d68aeec805f8c937fb_Communications%20Promo%20Icon.png"
            }
        ]
    },
    "headerMenu": {
        "currentLanguage": {
            "code": "TR",
            "icon": ""
        },
        "optionalLanguages": [
            {
                "code": "EN",
                "label": "English",
                "icon": "",
                "link": "/en"
            }
        ],
        "menuItems": [
            {
                "name": "ANA SAYFA",
                "link": "/",
                "icon": null
            },
            {
                "name": "DOĞRUDAN YATIRIM İÇGÖRÜLERİ",
                "link": "/insights",
                "icon": null
            },
            {
                "name": "EYLEM PLANLARI",
                "link": "/action-plans",
                "icon": null
            }
        ],
        "mobileMenuButton": {
            "icon": "",
            "label": "Menü"
        },
        "downloadButton": {
            "text": "PDF İndir",
            "link": "/documents/TR-FDI-Strategy-Report-Reduced.pdf",
            "icon": ""
        }
    },
    "form": {
        "placeholder": "E-posta adresiniz..",
        "submitText": "Başlayın",
        "successMessage": "Teşekkürler! Gönderiminiz alındı!",
        "errorMessage": "Form gönderilirken bir sorun oluştu."
    },
    "footerMenu": {
        "logoLink": "/",
        "downloadPdf": {
            "text": "PDF İndir",
            "link": "/documents/TR-FDI-Strategy-Report-Reduced.pdf",
            "icon": ""
        },
        "menuItems": [
            {
                "title": "ANA SAYFA",
                "link": "/"
            },
            {
                "title": "FDI ANALİZLERİ",
                "link": "/insights",
                "subLinks": [
                    {
                        "title": "FDI Görünümü & Trendler",
                        "link": "/insights#fdi-outlook"
                    },
                    {
                        "title": "Performans & Ekosistem",
                        "link": "/insights#fdi-performance"
                    },
                    {
                        "title": "Ana Bulgular",
                        "link": "/insights#key-findings"
                    },
                    {
                        "title": "Kaliteli FDI",
                        "link": "/insights#quality-fdi"
                    }
                ]
            }
        ],
        "actionPlan": {
            "title": "EYLEM PLANLARI",
            "link": "/action-plans"
        }
    }
}