/* eslint-disable @typescript-eslint/naming-convention */

export interface metadata {
  page: number
  pageCount: number
  pageSize: number
  totalCount: number
}

export interface BaseResponse<T> {
  _metadata: metadata
  code: number
  data: T
  message: string
}

export interface IAPIErrorConstructor {
  message: string
  status: number
  code: string
}

export class APIError extends Error {
  status: number

  constructor(errorResponse: IAPIErrorConstructor) {
    super(errorResponse.message)
    this.status = errorResponse.status
    this.message = errorResponse.message
  }

  toString() {
    return this.message
  }
}

export interface SubscribeRequest {
  FirstName: string;
  LastName: string;
  Email: string;
}