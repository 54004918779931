import React, { createContext, useContext, type ReactNode, useEffect, useState } from 'react';
import { en } from '../data/en'; // TS dosyasından içerik
import { tr } from '../data/tr';
import { useRouter } from 'next/router';
import type { Content } from 'models/content';

// Content verilerinin saklandığı global map
const contentMap: Record<'en' | 'tr', Content> = {
  en,
  tr,
};

interface ContentContextType {
  content: Content;
  showSubscriberModal: () => void;
  hideSubscriberModal: () => void;
  isSubscriberModalOpen: boolean;
}

// Context oluşturuluyor
const ContentContext = createContext<ContentContextType | null>(null);

// ContentProvider bileşeni
export const ContentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { locale } = useRouter();
  const [content, setContent] = useState(contentMap[locale as 'en' | 'tr'] || contentMap['en']);
  const [isSubscriberModalOpen, setSubscriberModalOpen] = useState(false);

  useEffect(() => {
    setContent(contentMap[locale as 'en' | 'tr'] || contentMap['en']);
  }, [locale]);

  const showSubscriberModal = () => setSubscriberModalOpen(true);
  const hideSubscriberModal = () => setSubscriberModalOpen(false);

  return (
    <ContentContext.Provider value={{ content, showSubscriberModal, hideSubscriberModal, isSubscriberModalOpen }}>
      {children}
    </ContentContext.Provider>
  );
};

// useContent hook'u ile içerik erişimi
export const useContent = () => {
  const context = useContext(ContentContext);
  if (!context) {
    throw new Error('useContent must be used within a ContentProvider');
  }
  return context;
};
